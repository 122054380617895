import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify, ServiceWorker } from "aws-amplify";
import awsExports from "./aws-exports";
import { Auth0Provider } from '@auth0/auth0-react';
// import { ServiceWorker } from 'aws-amplify/utils';

import * as Sentry from "@sentry/react";
import './i18n';


// (async () => {
//   const serviceWorker = new ServiceWorker();
//   Amplify.configure(awsExports);

//   try {
//     var registeredServiceWorker = await serviceWorker.register('./service-worker.js', '/');
    
//     // Rest of your asynchronous code...
//     serviceWorker.enablePush('BLx__NGvdasMNkjd6VYPdzQJVBkb2qafh')
//       .then((token) => {
//         console.log(token);
//       });

//     setInterval(() => {
//       registeredServiceWorker.send({
//         'message': 'CleanAllCache'
//       });
//     }, 3000);
//   } catch (error) {
//     console.error('Error registering service worker:', error);
//   }
// })();


Sentry.init({
  dsn: "https://20a1625b7f2d5261e9e3b763b0fabaea@o4506774314745856.ingest.sentry.io/4506774315991040",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


// Rest of your synchronous code...
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

