import React from 'react';
import { useTranslation } from 'react-i18next';

function Loading() {
  const { t } = useTranslation();

  return (
    <div className="spinner-container">
      <div className="spinner"></div>
      <div className="loading-text">{t('loadingInProgress')}</div>
    </div>
  );
}

export default Loading;
