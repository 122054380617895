import React from "react";
import LibraryEpisode from "./LibraryEpisode"; // This component needs to be created
import './Library.css'; // Import the CSS file

const Library = ({ episodes, currentEpisodeId, setCurrentEpisode, audioRef, isPlaying, setEpisodes, libraryStatus, serie, profile }) => {
    return (
        <div className={`extplayer-library-container ${libraryStatus ? "active" : ""}`}>
            <div className="extplayer-library-header">
                {serie && (
                    <>
                        <img src={serie.img} alt={serie.title} className="extplayer-library-series-image" />
                        <h2 className="extplayer-library-title">{serie.title}</h2>
                        <p className="extplayer-library-desc">{serie.desc}</p>
                    </>
                )}
            </div>
            <div className="extplayer-library-episodes-container">
                {episodes.map((episode) => (
                    <LibraryEpisode
                        currentEpisodeId={currentEpisodeId}
                        episode={episode}
                        episodes={episodes}
                        setCurrentEpisode={setCurrentEpisode}
                        key={episode._id}
                        audioRef={audioRef}
                        isPlaying={isPlaying}
                        setEpisodes={setEpisodes}
                    />
                ))}
            </div>
        </div>
    );
};

export default Library;
