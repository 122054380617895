import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Thumbnail from './Thumbnail';
import { faChevronRight, faFilter, faChevronLeft, faMagnifyingGlass, faCog, faBook, faPlay, faCompactDisc, faMicrophoneLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from './Carousel';
import { fetchAllMyContent } from './Fetching';
import { pushGTMEvent } from '../GoogleAnalytics';
import { DataContext } from '../DataContext';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';

const Explore = ({ handlePlay, handlePause, getToken }) => {
  const { t } = useTranslation(); // useTranslation hook for i18n
  const { myEpisodes, mySeries, loadMyContent, isLoadingMyContent } = useContext(DataContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingEpisodes, setLoadingEpisodes] = useState(true); // new state for loading
  const [loadingSeries, setLoadingSeries] = useState(true);
  const [loadingContent, setLoadingContent] = useState(true);  // New state to track if content has been loaded
  const navigate = useNavigate();

  pushGTMEvent('page_view', 'Page Interaction', window.location);

  async function load() {
    await loadMyContent(getToken);
    await setLoadingContent(false); // Set to true after loading content
    await setLoadingEpisodes(false);
    await setLoadingSeries(false);
    console.log("loaded");
  }

  useEffect(() => {
    if (!searchQuery && loadingContent) {
      load();
    } else {
      setLoadingEpisodes(false);
      setLoadingSeries(false);
      setLoadingContent(false);
    }
  }, [myEpisodes, mySeries, getToken]);

  if (loadingEpisodes || loadingSeries || loadingContent || isLoadingMyContent) {
    return (
      <div className="content-package">
        <Loading />
      </div>
    );
  }

  if (!myEpisodes.length && !mySeries.length) {
    return (
      <div className="content-package">
        <div className="details">
          <h1 id="baseSelectorTitle">{t("You don't have any content yet.")}</h1>
          <div className="outLinks">
            <button onClick={() => navigate("/app/generate/episode")} className="big-button">
              {t("Generate")}
            </button>
          </div>
          <div style={{ width: "70%", height: "30%", paddingBottom: "100%", position: "relative" }}>
            <img src="/gif/empty.gif" width="100%" height="40%" style={{ position: "absolute" }} frameBorder="0" className="giphy-embed" allowFullScreen />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="content-package" id="library">
      <div id="filter-container" className="filter-box">
        <FontAwesomeIcon icon={faFilter} className="search-icon" />
        <input
          id="episode-filter"
          type="text"
          placeholder={t("Filter")}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <Carousel title={t("Series")} handlePlay={handlePlay} searchQuery={searchQuery} inCommingElements={mySeries} handleClick={() => { console.log("click") }} loading={loadingSeries} />

      <Carousel title={t("Episodes")} handlePlay={handlePlay} searchQuery={searchQuery} inCommingElements={myEpisodes} handleClick={() => { console.log("click") }} loading={loadingEpisodes} />
    </div>
  );
};

export default Explore;
