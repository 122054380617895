import React from "react";
import './Episode.css'; // Import the CSS file
import SupportButton from './SupportButton'; // Import the SupportButton component

const Episode = ({ currentEpisode, profile }) => {
    return (
        <div className="extplayer-episode-container">
            <img src={currentEpisode.img} alt={currentEpisode.title} className="extplayer-episode-img" />
            <h2 className="extplayer-episode-h1">{currentEpisode.title}</h2>
            <div className="extplayer-episode-h2-container">
                <h3 className="extplayer-episode-h2">{currentEpisode.desc}</h3>
            </div>
            {profile && (
					<SupportButton
						supportLinkTitle={profile.supportLinkTitle}
						supportLinkPayment={profile.supportLinkPayment}
					/>
				)}
        </div>
    );
};

export default Episode;
