import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import components
import Player from "./Player";
import Episode from "./Episode";
import Library from "./Library";
import Nav from "./Nav";
import Credit from "./Credit";

// Assume fetchSerie is a function that fetches series data
import { fetchAudio, fetchSerie } from '../../components/Fetching'; // Adjust the path as needed
import './SeriePlayer.css'; // Import the CSS file

const SeriePlayer = () => {
    const { t } = useTranslation(); // Initialize useTranslation hook
    const { serieId, episodeId } = useParams();
    const navigate = useNavigate();
    const audioRef = useRef(null);
    const [currentEpisode, setCurrentEpisode] = useState(null);
    const [episodes, setEpisodes] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [libraryStatus, setLibraryStatus] = useState(true);
    const [profile, setProfile] = useState(null); // Add state for profile data
    const [episodeInfo, setEpisodeInfo] = useState({
        currentTime: 0,
        duration: 0,
    });

    const [serie, setSerie] = useState(null); // Add state for series data

    useEffect(() => {
        const loadSerie = async () => {
            try {
                const serieData = await fetchSerie(serieId);

                setProfile(serieData ? serieData.creator : null); // Set profile data (if available
                console.log("profile: ", profile)
                setSerie(serieData); // Set series data
                setEpisodes(serieData.episodes);


                if (episodeId !== undefined) {
                    const initialEpisode = serieData.episodes.find(ep => ep._id === episodeId);
                    if (initialEpisode) {
                        setCurrentEpisode(initialEpisode);
                        setLibraryStatus(false);
                    } else {
                        // Handle case where the episode ID is invalid
                        console.error('Invalid episode ID');
                        navigate(`/${serieId}`);
                    }
                } else {
                    // Default to the first episode if no episodeId is provided
                    if (serieData.episodes.length > 0) {
                        setCurrentEpisode(serieData.episodes[0]);
                    }
                }
            } catch (error) {
                console.error('Error fetching serie:', error);
            }
        };

        console.log('serieId:', serieId);
        console.log('episodeId:', episodeId);

        loadSerie();
    }, [serieId, episodeId, navigate]);

    const fetchAndSetAudio = async (episodeId) => {
        try {
            const audioData = await fetchAudio(episodeId);
            setCurrentEpisode((prevEpisode) => {
                if (prevEpisode && prevEpisode._id === episodeId) {
                    // Check if the audioUrl is already set
                    if (prevEpisode.audioUrl) {
                        return prevEpisode;
                    }
                    return { ...prevEpisode, audioUrl: audioData.audio_presigned_url };
                }
                return prevEpisode;
            });
        } catch (error) {
            console.error('Error fetching audio:', error);
        }
    };

    useEffect(() => {
        if (currentEpisode) {
            fetchAndSetAudio(currentEpisode._id);
        }
    }, [currentEpisode]);

    const updateTimeHandler = (e) => {
        const currentTime = e.target.currentTime;
        const duration = e.target.duration;
        setEpisodeInfo(prevInfo => ({
            ...prevInfo,
            currentTime: currentTime || 0,
            duration: duration || 0,
        }));
    };

    const episodeEndHandler = async () => {
        let currentIndex = episodes.findIndex((episode) => episode._id === currentEpisode._id);
        let nextEpisode = episodes[(currentIndex + 1) % episodes.length];
        await setCurrentEpisode(nextEpisode);

        if (isPlaying) {
            audioRef.current.play();
        }
    };

    if (!currentEpisode) {
        return (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                  background: '#FFE9F3',
                }}
              >
                <img src="kupeel-logo-horizontal.png" style={{ borderRadius: '10px' }} height="70px" width="200px" alt="App Logo" className="logo-open" />
              </div>
            </div>
          );
    }

    return (
        <div className={`extplayer-serieplayer-app-container ${libraryStatus ? 'library-open' : ''}`}>
            <Nav 
            libraryStatus={libraryStatus} 
            setLibraryStatus={setLibraryStatus} 
            profile={profile ? profile._id : null} />
            <Episode currentEpisode={currentEpisode} profile={profile.profile}/>
            <Player
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                currentEpisode={currentEpisode}
                setCurrentEpisode={setCurrentEpisode}
                audioRef={audioRef}
                episodeInfo={episodeInfo}
                setEpisodeInfo={setEpisodeInfo}
                episodes={episodes}
                setEpisodes={setEpisodes}
                libraryStatus={libraryStatus}
            />
            <Library
                episodes={episodes}
                currentEpisodeId={currentEpisode.id}
                setCurrentEpisode={setCurrentEpisode}
                audioRef={audioRef}
                isPlaying={isPlaying}
                setEpisodes={setEpisodes}
                libraryStatus={libraryStatus}
                serie={serie} // Pass series data to Library component
            />
            <Credit profile={profile.profile} profileId={profile ? profile._id : null}/>
            <audio
                onLoadedMetadata={updateTimeHandler}
                onTimeUpdate={updateTimeHandler}
                onEnded={episodeEndHandler}
                ref={audioRef}
                src={currentEpisode?.audioUrl}
            />
        </div>
    );
};

export default SeriePlayer;
