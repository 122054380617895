import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { pushGTMEvent } from '../GoogleAnalytics';
import {  useNavigate } from 'react-router-dom';


const jingleUrl = './jingle.mp3'; // Replace with the actual path to your jingle

const FloatingPlayer = ({ audioSrc, title, onPlay, onPause, isPlaying, artwork = "./kupeel2.png", episode, series = "Your voice", artist = "Kupeel" }) => {
  const { t } = useTranslation(); // Initialize useTranslation hook
  const playerRef = useRef(null);
  const [combinedAudioSrc, setCombinedAudioSrc] = useState(null);

  const navigate = useNavigate();
  pushGTMEvent('page_view', 'Page Interaction', window.location);

  const updateMediaSession = (audioSrc, title) => {
    pushGTMEvent('media_session', 'Media Session', 'Media Session');
    if ('mediaSession' in navigator && audioSrc) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: title,
        artist: artist,
        album: series,
        artwork: [
          { src: artwork, sizes: '96x96', type: 'image/png' },
          { src: artwork, sizes: '128x128', type: 'image/png' },
          { src: artwork, sizes: '192x192', type: 'image/png' },
          { src: artwork, sizes: '256x256', type: 'image/png' },
          { src: artwork, sizes: '512x512', type: 'image/png' },
          { src: artwork, sizes: '1024x1024', type: 'image/png' },
        ]
      });
    }
  };

  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  useEffect(() => {
    const fetchAudio = async (url) => {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      return await audioContext.decodeAudioData(arrayBuffer);
    };

    const createCombinedAudio = async () => {
      if (!audioSrc) {
        setCombinedAudioSrc(null);
        return;
      }

      try {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const [jingleBuffer, mainAudioBuffer] = await Promise.all([
          fetchAudio(jingleUrl),
          fetchAudio(audioSrc)
        ]);

        const numberOfChannels = Math.max(jingleBuffer.numberOfChannels, mainAudioBuffer.numberOfChannels);

        const outputBuffer = audioContext.createBuffer(
          numberOfChannels,
          jingleBuffer.length + mainAudioBuffer.length + jingleBuffer.length,
          audioContext.sampleRate
        );

        for (let channel = 0; channel < numberOfChannels; channel++) {
          const outputData = outputBuffer.getChannelData(channel);
          const jingleData = jingleBuffer.getChannelData(Math.min(channel, jingleBuffer.numberOfChannels - 1));
          const mainData = mainAudioBuffer.getChannelData(Math.min(channel, mainAudioBuffer.numberOfChannels - 1));

          outputData.set(jingleData, 0);
          outputData.set(mainData, jingleData.length);
          outputData.set(jingleData, jingleData.length + mainData.length);
        }

        // Convert AudioBuffer to WAV file
        const interleaved = new Float32Array(outputBuffer.length * numberOfChannels);
        for (let i = 0; i < outputBuffer.length; i++) {
          for (let channel = 0; channel < numberOfChannels; channel++) {
            interleaved[i * numberOfChannels + channel] = outputBuffer.getChannelData(channel)[i];
          }
        }

        const wavBuffer = new ArrayBuffer(44 + interleaved.length * 2);
        const view = new DataView(wavBuffer);

        // RIFF identifier
        writeString(view, 0, 'RIFF');
        // RIFF chunk length
        view.setUint32(4, 36 + interleaved.length * 2, true);
        // WAVE identifier
        writeString(view, 8, 'WAVE');
        // FMT sub-chunk
        writeString(view, 12, 'fmt ');
        // FMT chunk length
        view.setUint32(16, 16, true);
        // Audio format (PCM)
        view.setUint16(20, 1, true);
        // Number of channels
        view.setUint16(22, numberOfChannels, true);
        // Sample rate
        view.setUint32(24, audioContext.sampleRate, true);
        // Byte rate (sample rate * block align)
        view.setUint32(28, audioContext.sampleRate * 4, true);
        // Block align (num channels * bytes per sample)
        view.setUint16(32, numberOfChannels * 2, true);
        // Bits per sample
        view.setUint16(34, 16, true);
        // Data sub-chunk
        writeString(view, 36, 'data');
        // Data chunk length
        view.setUint32(40, interleaved.length * 2, true);

        // Write the PCM samples
        let offset = 44;
        for (let i = 0; i < interleaved.length; i++) {
          view.setInt16(offset, interleaved[i] * 0x7FFF, true);
          offset += 2;
        }

        const combinedAudioBlob = new Blob([view], { type: 'audio/wav' });
        const combinedAudioUrl = URL.createObjectURL(combinedAudioBlob);

        setCombinedAudioSrc(combinedAudioUrl);
      } catch (error) {
        console.error('Error combining audio files:', error);
      }
    };

    createCombinedAudio();
  }, [audioSrc]);

  useEffect(() => {
    updateMediaSession(audioSrc, title);
  }, [audioSrc, title]);

  useEffect(() => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.audio.current.play();
      } else {
        playerRef.current.audio.current.pause();
      }
    }
  }, [combinedAudioSrc, isPlaying]);

  return (
    <div id="floating_player" className={audioSrc ? "activeAudio" : "nonActiveAudio"}>
      <AudioPlayer
        ref={playerRef}
        className="audio-player"
        autoPlay={isPlaying}
        src={combinedAudioSrc}
        onPlay={() => onPlay()}
        onPause={() => onPause()}
        header={<a className="rhap_header" style={{cursor:"pointer"}} onClick={()=> navigate(`/app/content/episode/${episode}`)}>{audioSrc ? title : t('notPlaying')}</a>}
      />
    </div>
  );
};

export default FloatingPlayer;
