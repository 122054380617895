import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCompass, faPlay } from "@fortawesome/free-solid-svg-icons";
import './Nav.css'; // Import the CSS file

const Nav = ({ libraryStatus, setLibraryStatus, profile }) => {
	return (
		<div className="extplayer-nav-container">
			<button className="extplayer-nav-button" onClick={() => setLibraryStatus(!libraryStatus)}>
				{libraryStatus ? "Player" : "Playlist"}
				{libraryStatus ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faBars} />}
				
			</button>
			{profile !== null && <a className="extplayer-nav-button" href={(window.location.origin + "/#/app/public/profile/" + profile)}>
				All Playlists 
				<FontAwesomeIcon icon={faCompass} />
			</a>}
		</div>
	);
};

export default Nav;
