import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../DataContext'; // Import DataContext
import './Language.css';

import { languageOptions } from './LanguageOptions';

function Language( {text=true, reload=false} ) {
  const { t, i18n } = useTranslation();
  const { language, setLanguage } = useContext(DataContext); // Use DataContext to get and set the language

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    if(reload) window.location.reload()
  };

  return (
    <div className="language-dropdown">
      {text ? <label htmlFor="language-select" className="language-label">{t('selectLanguage')}</label>: ""}
      <select
        id="language-select"
        value={language}
        onChange={handleLanguageChange}
        className="language-select"
      >
        {languageOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Language;
