// fetchData.js

import { pushGTMEvent } from '../GoogleAnalytics';

function extractLanguage(acceptLanguage) {
  if (!acceptLanguage) {
    return acceptLanguage;
  }
  // Split the input by comma to handle cases where there are multiple languages
  const languages = acceptLanguage.split(',');

  // Map each language tag to its ISO language code
  const isoLanguages = languages.map(lang => {
    // Extract the part before the first hyphen or semicolon
    const isoCode = lang.split('-')[0].split(';')[0].trim();
    return isoCode;
  });

  // Return the first ISO language code, or an array of all ISO codes if you prefer
  return isoLanguages[0];
}

const getDefaultLanguage = () => {
  const localStorageLanguage = localStorage.getItem('language');
  const browserLanguage = navigator.language;
  return extractLanguage(localStorageLanguage) || extractLanguage(browserLanguage) || 'en';
};

const fetchAllMyContent = async (token, language) => {
  language = language || getDefaultLanguage();
  pushGTMEvent('apicall', 'fetchAllMyContent', window.location);
  try {
    let headers = {
      'Authorization': 'Bearer ' + token,
      'Accept-Language': language,
    };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/getAllGeneratedContent`, {
      method: 'GET',
      headers: headers
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return {
      episodes: data.episodes.map((episode) => ({
        ...episode,
        type: "episode",
        id: episode._id,
        title: episode.title || episode._id || "unknown",
        imageUrl: episode.img || 'kupeel2.png',
        channel: 'kupeel',
        audio: 1,
        status: episode.status
      })),
      series: data.series.map((serie) => ({
        ...serie,
        type: "serie",
        id: serie._id,
        title: serie.title || serie._id || "unknown",
        imageUrl: serie.img || 'kupeel2.png',
        channel: 'kupeel',
        audio: 1,
        status: 'SUCCEEDED'
      }))
    };

  } catch (error) {
    console.error('Error fetching episodes:', error);
    return {
      episodes: [],
      series: []
    };
  }
}

const fetchSearch = async (query, token, language) => {
  language = language || getDefaultLanguage();
  pushGTMEvent('apicall', 'fetchSearch', window.location);
  try {
    let headers = {
      'Accept-Language': language,
    };
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/search`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ query: query })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return {
      episodes: data.episodes.sort((a, b) => b.score - a.score).map((episode) => ({
        ...episode,
        type: "episode",
        id: episode._id,
        title: episode.title || episode._id || "unknown",
        imageUrl: episode.img || 'kupeel2.png',
        channel: 'kupeel',
        audio: 1,
        status: episode.status
      })),
      series: data.series.sort((a, b) => a.score - b.score).map((serie) => ({
        ...serie,
        type: "serie",
        id: serie._id,
        title: serie.title || serie.name || serie._id || "unknown",
        imageUrl: serie.img || 'kupeel2.png',
        channel: 'kupeel',
        audio: 1,
        status: 'SUCCEEDED'
      }))
    };
  } catch (error) {
    console.error('Error fetching episodes:', error);
    return {
      episodes: [],
      series: []
    };
  }
};

const fetchEpisodes = async (token, language) => {
  language = language || getDefaultLanguage();
  pushGTMEvent('apicall', 'fetchEpisodes', window.location);
  try {
    let headers = {
      'Accept-Language': language,
    };
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/episode/all`, {
      method: 'GET',
      headers: headers
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.episodes.map((episode) => ({
      ...episode,
      type: "episode",
      id: episode._id,
      title: episode.title || episode._id || "unknown",
      imageUrl: episode.img || 'kupeel2.png',
      channel: 'kupeel',
      audio: 1,
      status: episode.status
    }))
  } catch (error) {
    console.error('Error fetching episodes:', error);
    return [];
  }
};

const fetchAudio = async (id, token, language) => {
  language = language || getDefaultLanguage();
  pushGTMEvent('apicall', 'fetchAudio', window.location);
  try {
    let headers = {
      'Accept-Language': language,
    };
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/episode/get-audio/` + id, {
      method: 'GET',
      headers: headers
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching audio:', error);
    return '';
  }
};

const fetchEpisode = async (id, token, language) => {
  language = language || getDefaultLanguage();
  pushGTMEvent('apicall', 'fetchEpisode', window.location);

  try {
    let headers = {
      'Accept-Language': language,
    };
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/episode/` + id, {
      method: 'GET',
      headers: headers
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    const episode = {
      ...data,
      type: "episode",
      id: data._id,
      title: data.title || data._id || "unknown",
      imageUrl: data.img || 'kupeel2.png',
      channel: 'kupeel',
      audio: 1,
      status: data.status
    }

    console.log(JSON.stringify(episode, null, 2));

    return episode;

  } catch (error) {
    console.error('Error fetching episode:', error);
    return {};
  }
}

const fetchTrending = async (token, language) => {
  language = language || getDefaultLanguage();
  let headers = {
    'Accept-Language': language,
  };
  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/getTrending`, {
      method: 'GET',
      headers: headers
    })
    const data = await response.json();
    return data.series.map((serie) => ({
      ...serie,
      type: "serie",
      id: serie._id,
      title: serie.title || serie._id || "unknown",
      imageUrl: serie.img || 'kupeel2.png',
      channel: 'kupeel',
      audio: 1,
      status: 'SUCCEEDED'
    }))
  } catch (error) {
    console.error('Error fetching series:', error);
    return [];
  }
}

const fetchSeries = async (token, language) => {
  language = language || getDefaultLanguage();
  pushGTMEvent('apicall', 'fetchSeries', window.location);
  try {
    let headers = {
      'Accept-Language': language,
    };
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/series/all`, {
      method: 'GET',
      headers: headers
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.series.map((serie) => ({
      ...serie,
      type: "serie",
      id: serie._id,
      title: serie.title || serie._id || "unknown",
      imageUrl: serie.img || 'kupeel2.png',
      channel: 'kupeel',
      audio: 1,
      status: 'SUCCEEDED'
    }))
  } catch (error) {
    console.error('Error fetching series:', error);
    return [];
  }
};

const fetchSerie = async (id, token, language) => {
  language = language || getDefaultLanguage();
  pushGTMEvent('apicall', 'fetchSerie', window.location);
  try {
    let headers = {
      'Accept-Language': language,
    };
    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/series/get-all-episodes/` + id, {
      method: 'GET',
      headers: headers
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    const serie = {
      ...data,
      ...data.series,
      type: "serie",
      id: data.series._id,
      title: data.series.title || data.series._id || "unknown",
      imageUrl: data.series.img || 'kupeel2.png',
      channel: 'kupeel',
      audio: 1,
      status: 'SUCCEEDED'
    }

    serie.episodes = serie.episodes.map((episode) => ({
      ...episode,
      type: "episode",
      id: episode._id,
      title: episode.title || episode._id || "unknown",
      imageUrl: episode.img || 'kupeel2.png',
      channel: 'kupeel',
      audio: 1,
      status: episode.status
    }))

    console.log(JSON.stringify(serie, null, 2));

    return serie;
  } catch (error) {
    console.error('Error fetching serie:', error);
    return {};
  }
}

export { fetchEpisode, fetchEpisodes, fetchAudio, fetchSerie, fetchSeries, fetchSearch, fetchAllMyContent, fetchTrending, getDefaultLanguage };
