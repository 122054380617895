import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import Library from './components/Library';
import FloatingPlayer from './components/FloatingPlayer';
import Generate from './components/Generate';
import GenerateSeries from './components/GenerateSeries';
import GenerateSeriesComplete from './components/GenerateSeriesComplete';
import Parameters from './components/Parameters';
import Subscription from './components/Subscription';
import Explore from './components/Explore';
import Topic from './components/Topic';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowRightFromBracket, faUser, faHeart, faMagnifyingGlass, faCog, faMicrophoneLines } from '@fortawesome/free-solid-svg-icons';
import LoginPage from './Login';
import { StripeProvider, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import EpisodePage from './components/EpisodePage';
import SeriePage from './components/SeriePage';
import { fetchAudio } from './components/Fetching';
import { pushGTMEvent } from './GoogleAnalytics';
import { DataContext } from './DataContext'; // Import DataContext

function MainApp() {
  const { t, i18n } = useTranslation(); // Initialize useTranslation hook
  const admin = process.env.REACT_APP_ADMINS.split(",");

  const [searchQuery, setSearchQuery] = useState('');
  const [stripePromise, setstripePromise] = useState(undefined);
  const [clientSecret, setclientSecret] = useState(undefined);
  const [stripeIntent, setStripeIntent] = useState(null);
  const [playerActive, setplayerActive] = useState(false);
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading, logout, user, language } = useAuth0();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [currentAudioSrc, setCurrentAudioSrc] = useState(null);
  const [currentTitle, setCurrentTitle] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoadingEpisode, setIsLoadingEpisode] = useState(false);
  const [episodePlaying, setEpisodePlaying] = useState(null);
  const [episodePlayingSerie, setepisodePlayingSerie] = useState(null);
  const [episodePlayingImg, setepisodePlayingImg] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [requirePayment, setRequirePayment] = useState(false);
  const { userSubscription, getUserSubscription, isLoadingUserSubscription } = useContext(DataContext); // Use DataContext
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  // if (i18n.language !== language) {
  //   i18n.changeLanguage(language);
  // }

  pushGTMEvent('page_view', 'Page Interaction', currentPath);

  const getToken = async () => {
    const defaultToken = process.env.REACT_APP_DEFAULT_TOKEN;
    console.log(JSON.stringify(user));
    if (user) {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            ui_locales: navigator.language,
            ignoreCache: true,
            scope: process.env.REACT_APP_AUTH0_SCOPE,
          },
        });
        console.log(token);
        return token;
      } catch (e) {
        try {
          logout({ logoutParams: { returnTo: window.location.origin } });
        } catch (err) {
          console.log(err);
          window.location.reload();
        }
        console.log('error-token:' + e);
        console.log(defaultToken);
      }
    }
    return defaultToken;
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    if (isPlaying) {
      setplayerActive(true);
    }
  }, [isPlaying]);

  const handleSearchEnter = (event) => {
    if (event.key === 'Enter') {
      pushGTMEvent('search', 'User Interaction', searchQuery);
      navigate(`/app/explore/${searchQuery}`);
    }
  };

  useEffect(() => {
    if (user) {
      console.log('user');
      console.log(user.sub);
      console.log(JSON.stringify(user));

      getUserSubscription(getToken).then((data) => {
        console.log(data);
        if (data.subscribed) {
          setIsSubscribed(true);
        } else {
          setstripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC));
          setclientSecret({ clientSecret: data.client_secret });
          setStripeIntent({
            "subscribed": data.subscribed,
            "customer": data.customer,
            "payment_intent_id": data.payment_intent_id,
            'client_secret': data.client_secret,
            'stripe_customer_id': data.stripe_customer_id
          });
          console.log("stripeIntent");
          console.log(data);
          console.log(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC));
          setRequirePayment(true);
        }
        
      }).catch((error) => {
        console.log(error);
        setIsSubscribed(false);
      });

      // Set user details in a cookie
      document.cookie = `user=${JSON.stringify(user)}`;

    }
  }, [user, isSubscribed, isAuthenticated]);

  const handlePlay = async (id, title) => {
    pushGTMEvent('play', 'Media Interaction', `Episode Played: ${id}`);

    console.log('handlePlay');
    const token = await getToken();

    if (episodePlaying === id) {
      setIsPlaying(true);
    } else {
      console.log(id);

      await setEpisodePlaying(id);
      await setIsLoadingEpisode(true);

      console.log(episodePlaying);

      var data = await fetchAudio(id, token);

      console.log(data);
      if (data) {
        if (data.episode_details.status === 'SUCCEEDED') {
          await setIsLoadingEpisode(false);
          await setIsPlaying(false);
          console.log('setEpisodeLink');
          console.log(data.audio_presigned_url);
          await setCurrentAudioSrc(data.audio_presigned_url);
          await setCurrentTitle(data.title || data.episode_details.title || data.name);
          await setepisodePlayingSerie(data.episode_details.series);
          await setepisodePlayingImg(data.episode_details.img);
          await setIsPlaying(true);
        } else if (data.episode_details.status === 'FAILED') {
          await setIsLoadingEpisode(false);
          await setIsPlaying(false);
          window.alert(t('episodeNotAvailable'));
        } else if (data.episode_details.status === 'GENERATING_MP3' || data.episode_details.status === 'PENDING') {
          await setIsLoadingEpisode(false);
          await setIsPlaying(false);
          window.alert(t('episodeStillGenerating'));
          await setEpisodePlaying(null);
        }
      } else {
        await setIsLoadingEpisode(false);
        await setIsPlaying(false);
        await setEpisodePlaying(null);
      }
    }
  };

  const handlePause = async () => {
    pushGTMEvent('pause', 'Media Interaction', `Episode Paused: ${episodePlaying}`);
    setIsPlaying(false);
  };

  if (isLoading || (isAuthenticated && !stripePromise && !isSubscribed && isLoadingUserSubscription)) {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            background: '#FFE9F3',
          }}
        >
          <img src="kupeel-logo-horizontal.png" style={{ borderRadius: '10px' }} height="70px" width="200px" alt="App Logo" className="logo-open" />
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    console.log('not authenticated');
    return <LoginPage isAuthenticated={isAuthenticated} loginWithRedirect={loginWithRedirect} />;
  }

  if (!isSubscribed && stripeIntent !== null && requirePayment && stripePromise) {
    console.log('not subscribed');
    console.log(stripeIntent);
    const options = {
      clientSecret: stripeIntent.client_secret,
    };

    return (
      <div id="fullpage">
        <div id="container">
          {!isSubscribed && stripeIntent !== null && clientSecret !== undefined && (
            <div className="container-payment">
              <Elements stripe={stripePromise} options={options}>
                <Subscription stripeIntent={stripeIntent} setIsSubscribed={setIsSubscribed} getToken={getToken} />
              </Elements>
            </div>
          )}
        </div>

        <div id="menu">
          <img src="kupeel-logo-horizontal.png" height="70px" width="200px" alt="App Logo" id="logo" />

          <button
            className={`menu-button`}
            onClick={() => {
              pushGTMEvent('logout', 'User Interaction', 'Logout Button Clicked'), logout({ logoutParams: { returnTo: window.location.origin } });
            }}
          >
            <FontAwesomeIcon className="menu-icon" icon={faArrowRightFromBracket} />
            <div>{t('signOut')}</div>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div id="fullpage" className={playerActive ? 'isPlaying' : ''}>
      <div id="container" className={playerActive ? 'isPlaying' : ''}>
        <div id="top-bar">
          <div id="search-container" className="search-box">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input
              id="episode-search"
              type="text"
              placeholder={t('browse')}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleSearchEnter}
            />
          </div>

          <div style={{ flex: 1 }}></div>
          <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => navigate('/app/parameters')}>
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#f0f0f0',
                padding: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon icon={faUser} size="lg" />
            </div>
          </div>
        </div>

        <div id="main-content">
          <div id="below-menu">
            <Routes>
              <Route
                path="/"
                element={<Explore handlePlay={handlePlay} handlePause={handlePause} setSearchQuery={setSearchQuery} getToken={getToken} />}
              />
              <Route
                path="/explore"
                element={<Explore setSearchQuery={setSearchQuery} handlePlay={handlePlay} handlePause={handlePause} getToken={getToken} />}
              />

              <Route
                path="/explore/:query"
                element={<Explore setSearchQuery={setSearchQuery} handlePlay={handlePlay} handlePause={handlePause} getToken={getToken} />}
              />

              <Route
                path="/topic/:query"
                element={<Topic handlePlay={handlePlay} handlePause={handlePause} setSearchQuery={setSearchQuery} getToken={getToken} />}
              />

              <Route path="/library" element={<Library getToken={getToken} handlePlay={handlePlay} handlePause={handlePause} />} />
              <Route path="/generate/episode" element={<Generate getToken={getToken} />} />
              <Route path="/generate/episode/:id" element={<Generate getToken={getToken} />} />
              <Route path="/parameters" element={<Parameters handlePause={handlePause} getToken={getToken} />} />

              <Route path="/generate/serie" element={<GenerateSeries getToken={getToken} />} />

              {admin.includes(user.sub) && <Route path="/admin/secret/generate/serie" element={<GenerateSeriesComplete getToken={getToken} />} />}
              <Route path="/generate/complete/serie" element={<GenerateSeriesComplete getToken={getToken} />} />

              
              <Route
                path="/content/episode/:id"
                element={
                  <EpisodePage
                    handlePlay={handlePlay}
                    handlePause={handlePause}
                    isPlaying={isPlaying}
                    episodePlaying={episodePlaying}
                    isLoadingEpisode={isLoadingEpisode}
                    isGenerating={isGenerating}
                    getToken={getToken}
                  />
                }
              />
              <Route
                path="/content/serie/:id"
                element={
                  <SeriePage
                    handlePlay={handlePlay}
                    handlePause={handlePause}
                    isPlaying={isPlaying}
                    episodePlaying={episodePlaying}
                    isLoadingEpisode={isLoadingEpisode}
                    isGenerating={isGenerating}
                    getToken={getToken}
                  />
                }
              />
            </Routes>
          </div>
        </div>

        <FloatingPlayer
          audioSrc={currentAudioSrc}
          title={currentTitle}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          isPlaying={isPlaying}
          artwork={episodePlayingImg}
          episode={episodePlaying}
          series={episodePlayingSerie}
        />
      </div>
      <div id="menu">
        <img src="kupeel-logo-horizontal.png" height="70px" width="200px" alt="App Logo" id="logo" />
        <span className="span-menu"></span>
        {userSubscription.plan_name !== "free" && (
        <button
          onClick={() => navigate('/app/generate/episode')}
          className={`menu-button ${currentPath.includes('/app/generate/episode') ? 'active-button' : ''} ${currentPath.includes('/app/generate/serie') ? 'active-button' : ''}`}
        >
          <FontAwesomeIcon className="menu-icon" icon={faMicrophoneLines} />
          <div>{t('createEpisode')}</div>
        </button>)}
        {userSubscription.plan_name !== "free" && (
        <button className={`menu-button ${currentPath === '/app/library' ? 'active-button' : ''}`} onClick={() => navigate('/app/library')}>
          <FontAwesomeIcon className="menu-icon" icon={faHeart} />
          <div>{t('myLibrary')}</div>
        </button>)}
        <button className={`menu-button ${currentPath.includes('/app/explore') || currentPath.endsWith('/app') ? 'active-button' : ''}`} onClick={() => navigate('/app/explore')}>
          <FontAwesomeIcon className="menu-icon" icon={faMagnifyingGlass} />
          <div>{t('explore')}</div>
        </button>
        <button className={`menu-button ${currentPath === '/app/parameters' ? 'active-button' : ''}`} onClick={() => navigate('/app/parameters')}>
          <FontAwesomeIcon className="menu-icon" icon={faCog} />
          <div>{t('parameters')}</div>
        </button>
      </div>
    </div>
  );
}

export default MainApp;
