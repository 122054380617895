import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Thumbnail from './Thumbnail';
import { faChevronRight, faChevronLeft, faMagnifyingGlass, faCog, faBook, faPlay, faCompactDisc, faMicrophoneLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from './Carousel';
import { fetchEpisodes, fetchSeries, fetchSearch } from './Fetching';
import { pushGTMEvent } from '../GoogleAnalytics';
import { useTranslation } from 'react-i18next';

const Topic = ({ handlePlay, handlePause, setSearchQuery, getToken }) => {
  const { query } = useParams();
  const { t } = useTranslation();
  const [episodes, setEpisodes] = useState([]);
  const [series, setSeries] = useState([]);
  const [loadingEpisodes, setLoadingEpisodes] = useState(true); // new state for loading
  const [loadingSeries, setLoadingSeries] = useState(true);

  pushGTMEvent('page_view', 'Page Interaction', window.location);
  pushGTMEvent("topic_view", "Topic", query);

  async function load() {
    var token = await getToken();
    if (query) {
      fetchSearch(query, token).then((data) => {
        setEpisodes(data.episodes);
        setSeries(data.series);
        setLoadingEpisodes(false); // set loadingEpisodes to false once the data has been fetched
        setLoadingSeries(false);
      }).catch((error) => {
        console.log(error);
        setLoadingEpisodes(false);
        setLoadingSeries(false);
      });
    } else {
      fetchEpisodes(token).then((data) => {
        setEpisodes(data);
        setLoadingEpisodes(false); // set loadingEpisodes to false once the data has been fetched
      }).catch((error) => {
        console.log(error);
        setLoadingEpisodes(false);
      });

      fetchSeries(token).then((data) => {
        setSeries(data);
        setLoadingSeries(false); // set loadingSeries to false once the data has been fetched
      }).catch((error) => {
        console.log(error);
        setLoadingSeries(false);
      });
    }
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setLoadingEpisodes(true);
    setLoadingSeries(true);
    load();
    setSearchQuery('');
  }, [query]);

  return (
    <div className="content-package" id="library">
      {(series.length !== 0 || episodes.length !== 0) && query && (
        <h2>{t('resultsForTopic', { query })}</h2>
      )}

      {series.length === 0 && episodes.length === 0 && !loadingSeries && !loadingEpisodes && (
        <h2>{t('noResultsForTopic', { query })}</h2>
      )}

      <Carousel
        title={t('series')}
        handlePlay={handlePlay}
        searchQuery={""}
        inCommingElements={series}
        handleClick={() => { console.log("click") }}
        loading={loadingSeries}
      />

      <Carousel
        title={t('episodes')}
        handlePlay={handlePlay}
        searchQuery={""}
        inCommingElements={episodes}
        handleClick={() => { console.log("click") }}
        loading={loadingEpisodes}
      />
    </div>
  );
};

export default Topic;
