import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faSync } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './SeriePage.css'; // Assuming you have a corresponding CSS file

function ButtonPlayEpisode({ id, isPlaying, isGenerating, isLoadingEpisode, episodePlaying, handlePlay, handlePause }) {
    const { t } = useTranslation(); // Initialize useTranslation hook

    return (
        <>
            {episodePlaying !== id ?
                <button className="play-button-episode-list" style={{ backgroundColor: "#FF7817" }} onClick={(e) => { e.stopPropagation(); handlePlay(id) }}>
                    <FontAwesomeIcon icon={faPlay} /> {t('play')}
                </button>
                : (episodePlaying === id && isLoadingEpisode) ?
                    (<button className="play-button-episode-list" style={{ backgroundColor: "grey" }} >
                        <FontAwesomeIcon icon={faSync} /> {t('loading')}
                    </button>)
                    : (!isPlaying && episodePlaying === id && isGenerating) ?
                        (<button className="play-button-episode-list" style={{ backgroundColor: "grey" }} onClick={(e) => { e.stopPropagation(); handlePlay(id) }}>
                            <FontAwesomeIcon icon={faPlay} /> {t('retry')}
                        </button>)
                        : (!isPlaying && episodePlaying === id && !isLoadingEpisode) ?
                            (<button className="play-button-episode-list" style={{ backgroundColor: "#CE3612" }} onClick={(e) => { e.stopPropagation(); handlePlay(id) }}>
                                <FontAwesomeIcon icon={faPlay} /> {t('resume')}
                            </button>)
                            :
                            (<button className="play-button-episode-list" style={{ backgroundColor: "red" }} onClick={(e) => { e.stopPropagation(); handlePause() }}>
                                <FontAwesomeIcon icon={faPause} /> {t('pause')}
                            </button>)
            }
        </>
    );
}

export default ButtonPlayEpisode;
