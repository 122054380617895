import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DataContext } from "../DataContext";
import Thumbnail from "./Thumbnail";
import {
  faChevronRight,
  faChevronLeft,
  faMagnifyingGlass,
  faCog,
  faBook,
  faPlay,
  faCompactDisc,
  faMicrophoneLines,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "./Carousel";
import TopicCarousel from "./TopicCarousel";
import { fetchEpisodes, fetchSeries, fetchSearch } from "./Fetching"; // Ensure these functions are defined in your Fetching file
import { pushGTMEvent } from "../GoogleAnalytics";

const Explore = ({ handlePlay, handlePause, setSearchQuery, getToken }) => {
  const { t } = useTranslation(); // Initialize useTranslation hook
  const { query } = useParams();
  const { episodes, series, loadContent } = useContext(DataContext); // trending, loadTrending
  const [searchResults, setSearchResults] = useState({
    episodes: [],
    series: [],
  });
  const [audiobooks, setAudiobooks] = useState([]); // New state for audiobooks
  const [audioguides, setAudioguides] = useState([]); // New state for audioguides
  const [contentLoaded, setContentLoaded] = useState(false); // New state to track if content has been loaded

  pushGTMEvent("page_view", "Page Interaction", window.location);

  // Load content without query from context
  useEffect(() => {
    if (!query && !contentLoaded && !episodes.length && !series.length) {
      loadContent(getToken);
      // loadTrending(getToken);
      // const fetchAdditionalContent = async () => {
      //   const token = await getToken();
      // const [audiobooksData, audioguidesData] = await Promise.all([
      //   fetchSearch('book', token),
      //   fetchSearch('guide', token)
      // ]);
      // setAudiobooks(audiobooksData.series); // Assuming fetchSearch returns { series: [], episodes: [] }
      // setAudioguides(audioguidesData.series); // Assuming fetchSearch returns { series: [], episodes: [] }
      // };
      // fetchAdditionalContent();
      setContentLoaded(true); // Set to true after loading content
    }
  }, [
    query,
    episodes,
    series,
    contentLoaded,
    loadContent,
    // loadTrending,
    getToken,
  ]);

  // Handle search query
  useEffect(() => {
    if (query) {
      console.log("Search query:", query);
      const performSearch = async () => {
        const token = await getToken();
        fetchSearch(query, token)
          .then((data) => {
            setSearchResults({
              episodes: data.episodes.filter((episode) => episode.score > 0),
              series: data.series.filter((serie) => serie.score > 0),
            });
          })
          .catch((error) => {
            console.error("Error during search:", error);
          });
      };
      performSearch();
    }
  }, [query, getToken]);

  return (
    <div className="content-package" id="library">
      {(searchResults.series.length !== 0 ||
        searchResults.episodes.length !== 0) &&
        query && <h2>{t("searchResultsFor", { query })}</h2>}

      {searchResults.series.length === 0 &&
        searchResults.episodes.length === 0 &&
        query && <h2>{t("noResultsFoundFor", { query })}</h2>}

      {!query && <TopicCarousel />}

      {query ? (
        <>
          {searchResults.series.length !== 0 && (
            <Carousel
              title={t("series")}
              handlePlay={handlePlay}
              inCommingElements={searchResults.series}
              handleClick={() => {
                console.log("Series clicked");
              }}
              loading={!searchResults.series.length}
            />
          )}
          {searchResults.episodes.length !== 0 && (
            <Carousel
              title={t("episodes")}
              handlePlay={handlePlay}
              inCommingElements={searchResults.episodes}
              handleClick={() => {
                console.log("Episode clicked");
              }}
              loading={!searchResults.episodes.length}
            />
          )}
        </>
      ) : (
        <>
          {/* <Carousel
            title={t('todaysSelection')}
            handlePlay={handlePlay}
            inCommingElements={trending}
            handleClick={() => { console.log("Series clicked") }}
            loading={!trending.length}
          /> */}

          {audiobooks.length ? (
            <Carousel
              title={t("audiobooks")}
              handlePlay={handlePlay}
              inCommingElements={audiobooks}
              handleClick={() => {
                console.log("Audiobook clicked");
              }}
              loading={!audiobooks.length}
            />
          ) : null}
          {audioguides.length ? (
            <Carousel
              title={t("audioguides")}
              handlePlay={handlePlay}
              inCommingElements={audioguides}
              handleClick={() => {
                console.log("Audioguide clicked");
              }}
              loading={!audioguides.length}
            />
          ) : null}

          <Carousel
            title={t("series")}
            handlePlay={handlePlay}
            inCommingElements={series}
            handleClick={() => {
              console.log("Series clicked");
            }}
            loading={!series.length}
          />
          <Carousel
            title={t("episodes")}
            handlePlay={handlePlay}
            inCommingElements={episodes}
            handleClick={() => {
              console.log("Episode clicked");
            }}
            loading={!episodes.length}
          />
        </>
      )}
    </div>
  );
};

export default Explore;
