import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import './Player.css'; // Import the CSS file

const Player = ({
    currentEpisode,
    setCurrentEpisode,
    isPlaying,
    setIsPlaying,
    audioRef,
    episodeInfo,
    setEpisodeInfo,
    episodes,
    setEpisodes,
}) => {
    const pointer = { cursor: "pointer" };

    const playSongHandler = () => {
        if (isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
        } else {
            audioRef.current.play();
            setIsPlaying(true);
        }
    };

    const togglePlayPauseIcon = () => (isPlaying ? faPause : faPlay);

    const getTime = (time) => {
        let minute = Math.floor(time / 60);
        let second = ("0" + Math.floor(time % 60)).slice(-2);
        return `${minute}:${second}`;
    };

    const dragHandler = (e) => {
        audioRef.current.currentTime = e.target.value;
        setEpisodeInfo({ ...episodeInfo, currentTime: e.target.value });
    };

    const skipTrackHandler = async (direction) => {
        let currentIndex = episodes.findIndex((episode) => episode._id === currentEpisode._id);
        if (direction === "skip-forward") {
            await setCurrentEpisode(episodes[(currentIndex + 1) % episodes.length]);
        } else if (direction === "skip-back") {
            if ((currentIndex - 1) % episodes.length === -1) {
                await setCurrentEpisode(episodes[episodes.length - 1]);
            } else {
                await setCurrentEpisode(episodes[(currentIndex - 1) % episodes.length]);
            }
        }
        if (isPlaying) {
            audioRef.current.play();
        }
        updateMediaSession();
    };

    const getProgress = (episodeInfo) => {
        return episodeInfo ? Math.round((episodeInfo.currentTime * 100) / episodeInfo.duration) : 0;
    };

    const updateMediaSession = () => {
        console.log("currentEpisode")
        console.log(currentEpisode)
        if ('mediaSession' in navigator && currentEpisode) {
            const artwork = currentEpisode.img ? [
                { src: currentEpisode.img, sizes: '96x96', type: 'image/png' },
                { src: currentEpisode.img, sizes: '128x128', type: 'image/png' },
                { src: currentEpisode.img, sizes: '192x192', type: 'image/png' },
                { src: currentEpisode.img, sizes: '256x256', type: 'image/png' },
                { src: currentEpisode.img, sizes: '512x512', type: 'image/png' },
                { src: currentEpisode.img, sizes: '1024x1024', type: 'image/png' },
            ] : [];

            navigator.mediaSession.metadata = new MediaMetadata({
                title: currentEpisode.title || 'Unknown Title',
                artist: currentEpisode?.creator?.profile?.name || 'Unknown Artist',
                album: currentEpisode.series || 'Unknown Series',
                artwork: artwork,
            });

            navigator.mediaSession.setActionHandler('play', () => {
                playSongHandler();
                updateMediaSession();
            });

            navigator.mediaSession.setActionHandler('pause', () => {
                playSongHandler();
                updateMediaSession();
            });

            navigator.mediaSession.setActionHandler('previoustrack', () => {
                skipTrackHandler('skip-back');
            });

            navigator.mediaSession.setActionHandler('nexttrack', () => {
                skipTrackHandler('skip-forward');
            });
        }
    };

    useEffect(() => {
        updateMediaSession();
    }, [currentEpisode, isPlaying]);

    return (
        <div className="extplayer-player-container">
            <div className="extplayer-player-timecontrol-container">
                <p className="extplayer-player-p">{getTime(episodeInfo?.currentTime || 0)}</p>
                <div className="extplayer-player-track">
                    <input
                        onChange={dragHandler}
                        min={0}
                        max={episodeInfo?.duration || 0}
                        value={episodeInfo?.currentTime || 0}
                        type="range"
                        className="extplayer-player-input"
                    />
                    <div className="extplayer-player-animatetrack" style={{ transform: `translateX(${getProgress(episodeInfo)}%)` }}></div>
                </div>
                <p className="extplayer-player-p">{getTime(episodeInfo?.duration || 0)}</p>
            </div>

            <div className="extplayer-player-playcontrol-container">
                <FontAwesomeIcon
                    onClick={() => skipTrackHandler("skip-back")}
                    icon={faAngleLeft}
                    size="2x"
                    style={pointer}
                />
                <FontAwesomeIcon
                    onClick={playSongHandler}
                    icon={togglePlayPauseIcon()}
                    size="2x"
                    style={pointer}
                />
                <FontAwesomeIcon
                    onClick={() => skipTrackHandler("skip-forward")}
                    icon={faAngleRight}
                    size="2x"
                    style={pointer}
                />
            </div>
        </div>
    );
};

export default Player;
