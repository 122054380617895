import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Conditions.css'; // Ensure you point to the correct CSS file


const Conditions = ({ isAuthenticated, loginWithRedirect }) => {

  const navigate = useNavigate();

  if (!isAuthenticated) {
    return (
      <div className="conditions-container">
        <div className="conditions-cardPack">
          <div className="conditions-card">
            <img src="/kupeel-logo.png" alt="Logo" className="login-logo" />
            <button id="login" onClick={() => {navigate("/app/explore")}} className="big-button-lato">
              Sign In / Sign Up
            </button>

            <h1>Terms and Conditions of Kupeel LTD (8th May 2024)</h1>

<h2>1. Introduction</h2>

<p>Welcome to Kupeel, a pioneering platform for AI-enhanced audio content generation, accessible at crowd.kupeel.com. Our services enable users to transform text, audio questions, or URLs into diverse forms of audio content such as podcasts, audioguides, and audiobooks using advanced artificial intelligence technology. These Terms and Conditions constitute a legally binding agreement between you and Kupeel LTD, a company registered in England and Wales under company number 14878016, with its registered office located at 55 Marchmont Street, WC1N 1AP. By accessing or using any part of our service, you agree to be bound by these Terms and Conditions. If you do not fully agree to these terms, you are not authorized to access or otherwise use our services. The services offered by Kupeel LTD under these Terms and Conditions include any services Kupeel LTD makes available now or in the future, including but not limited to the creation, distribution, and management of audio content generated through our AI systems.</p>

<h2>2. Acceptance of Terms</h2>

<p>By registering, accessing, or using the services of Kupeel, you confirm that you are at least 18 years of age and possess the legal capacity to enter into and adhere to these terms. Additionally, you affirm that any credit or debit card information you supply is true and complete, that charges incurred by you will be honored by your credit/debit card company, and that you will pay the charges incurred by you at the posted prices, including any applicable taxes. These Terms and Conditions apply to all users of the services, including without limitation users who are contributors of content, information, and other materials or services, registered or otherwise. By using Kupeel, you also consent to the collection, transfer, manipulation, storage, disclosure, and other uses of your information as described in our Privacy Policy, which is available on our website and incorporated here by reference.</p>

<h2>3. Description of Service</h2>

Kupeel offers a unique platform that empowers users to create personalized audio content through the innovative use of AI technologies. Our service utilizes AI models that have either been developed internally by our team or acquired through partnerships with external entities. These AI models are capable of converting submitted textual data, spoken audio inputs, or content extracted from provided URLs into structured audio formats like podcasts or audiobooks. The platform serves as a collaborative and social environment where users can not only generate their own content but also access and engage with the content created by other users. Through this service, Kupeel aims to foster a dynamic community of content creators and consumers who are interested in a diverse array of topics and niches. Users of Kupeel can expect a user-friendly interface that provides options to customize and enhance their audio content, manage their created and favorite content, and interact with other community members through feedback and shared interests. Please note that while Kupeel strives to provide a robust and error-resistant service, the nature of our platform as an innovative tech offering means that updates, maintenance, and unexpected technical issues may occasionally occur, affecting availability and functionality.

<h2>4. User-Generated Content</h2>

<p>As a user of Kupeel, you have the capability and authorization to generate audio content using our AI-driven tools. It is important to understand your responsibilities and the rules concerning the content you create. When you submit text, audio, or URLs for transformation into audio content, you are fully responsible for the originality and legality of the input material and the final content.
<br/>
Content Guidelines:

<br/>

<h4>Prohibited Content:</h4> You must not submit or create content that includes hate speech, promotes discrimination of any kind, infringes on intellectual property rights, or contains explicit or violent material. Content that is defamatory, harassing, or in violation of any law (including privacy laws and regulations concerning copyrighted materials) is strictly prohibited.
<h4>Compliance with Laws:</h4> Your use of our services must comply with all applicable local, national, and international laws and regulations. This includes, but is not limited to, laws pertaining to copyright and privacy, anti-discrimination, and public decency.
<h4>Responsibility for Content:</h4> You accept full responsibility for the content you create, including any consequences that arise from sharing or publishing it. Kupeel does not endorse any user-generated content nor does it assume liability for any user actions or content provided by users.</p>

<h2>5. Intellectual Property Rights</h2>

<p><br/>Ownership and Rights to Use:<br/>
All content generated on Kupeel by users becomes the property of Kupeel LTD. By creating content on our platform, you grant Kupeel a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, reproduce, adapt, modify, publish, translate, create derivative works from, distribute, perform, and display such content (in whole or part) and/or to incorporate it in other works in any form, media, or technology now known or later developed.

<br/>Enterprise Usage:<br/>
Kupeel provides specific licensing options for enterprise users wishing to utilize user-generated content externally, such as on their own websites or apps, via APIs or iframes. Enterprises interested in these services should contact us directly to discuss subscription plans and licensing terms.

<br/>Protection of Intellectual Property:<br/>
Kupeel respects the intellectual property rights of others and expects users to do the same. We will respond to clear notices of alleged copyright infringement. Users who repeatedly infringe or are repeatedly charged with infringing the intellectual property rights of others will be banned from using our services.
</p>

<p><br/>
AI-Generated Content and Prohibited Uses:<br/>
All content generated by Kupeel, including any AI-synthesized voices, is intended for legitimate and lawful purposes only. Users are strictly prohibited from using any AI-generated content for fraudulent activities, scams, the creation or dissemination of fake news, or any other unlawful purposes. Any user found engaging in such activities will be held accountable for their actions and may face legal action from Kupeel or affected third parties. It is the responsibility of each user to ensure that their use of Kupeel’s services complies with all applicable laws and regulations.
<br/><br/>
Reliability of AI-Generated Content:<br/>
Kupeel uses sophisticated AI models to generate content based on user inputs. While we strive for accuracy and relevance, the content generated by our AI models may not always reflect the most current or accurate information. Kupeel is not responsible for any inaccuracies, errors, or potential copyright issues in the content created by our AI systems. If you believe that any content generated by Kupeel infringes on your copyright or contains significant errors, please contact us immediately at julien@bardin.me. We are committed to addressing and resolving such issues promptly and efficiently.
</p>


<h2>6. Account Termination and Suspension</h2>
<p>
<br/>Grounds for Termination and Suspension:<br/>
Kupeel reserves the right to suspend or terminate your account and access to the services if you are found to have violated any of the terms and conditions, including but not limited to breaches concerning user-generated content, intellectual property rights, and payment obligations. Further grounds for suspension or termination include:

<br/>- Engaging in fraudulent, illegal, or unethical activities.
<br/>- Creating and disseminating prohibited content as defined in section 4.
<br/>- Repeatedly infringing upon the intellectual property rights of others.
<br/>- Failing to rectify a breach of these terms after receiving a warning or request to comply from Kupeel.
<br/><br/>
Procedure for Termination and Suspension:<br/>
When a potential violation occurs, Kupeel will typically issue a warning to the user, except in cases where the breach necessitates immediate action. The warning will detail the nature of the violation and any corrective action that may be taken to avoid suspension or termination. If the issue is not resolved, or if repeated violations occur, Kupeel may proceed to suspend or terminate the user's account. Users will be informed of the decision to suspend or terminate their account, with details regarding the reasons and any potential recourse or appeal.
<br/>
Effects of Termination:<br/>
Upon termination, all rights granted to you under these terms will cease immediately, and you must cease all activities authorized by these terms, including the use of any services. Kupeel reserves the right to delete any of your content from its databases and any other records at its sole discretion.</p>

<h2>7. Dispute Resolution</h2>

<p><br/>Governing Law:<br/>
These Terms and Conditions shall be governed by and construed in accordance with the laws of England and Wales, without regard to its conflict of law provisions.
<br/>
Arbitration Agreement:<br/>
In the interest of resolving disputes between you and Kupeel in the most expedient and cost-effective manner, you and Kupeel agree that any and all disputes arising in connection with these Terms shall be resolved by binding arbitration. Arbitration is more informal than a lawsuit in court and uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court, and can be subject to very limited review by courts.
<br/>
Arbitration Procedures: <br/>Arbitration under these Terms will be conducted by a single arbitrator under the rules then prevailing of the American Arbitration Association. The arbitration will be conducted in the English language, in London, England.
<br/>Exceptions to Arbitration: <br/>Both parties agree that nothing herein shall be deemed to waive, preclude, or otherwise limit either of our rights to (i) bring an individual action in small claims court, (ii) pursue enforcement actions through applicable federal, state, or local agencies where such actions are available, (iii) seek injunctive relief in a court of law, or (iv) to file suit in a court of law to address intellectual property infringement claims.<br/>
Notice Requirement and Informal Dispute Resolution:<br/>
Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute describing the nature and basis of the claim or dispute and the requested relief. A Notice to Kupeel should be sent to: 55 Marchmont Street, WC1N 1AP, London, England. After the Notice is received, you and Kupeel may attempt to resolve the claim or dispute informally. If you and Kupeel do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding.
</p>

<h2>8. Modifications to the Terms</h2>

<p><br/>
Right to Modify Terms:<br/>
Kupeel LTD reserves the right, at its sole discretion, to change, modify, add, or remove portions of these Terms and Conditions at any time. It is your responsibility to check these Terms and Conditions periodically for changes. Your continued use of the Services following the posting of changes will mean that you accept and agree to the changes. As long as you comply with these Terms and Conditions, Kupeel grants you a personal, non-exclusive, non-transferable, limited privilege to enter and use the Services.
<br/>
Notification of Changes:<br/>
Whenever significant changes are made to the Terms and Conditions, Kupeel will make a reasonable effort to notify you via email or prominent notice on our website. The notice will designate a reasonable period after which the new terms will take effect. We understand the importance of changes in terms and conditions and will ensure clarity in how they impact your rights and responsibilities.
<br/>
Acceptance of Modified Terms:<br/>
You acknowledge and agree that if you use the Services after the date on which the Terms have changed, Kupeel will treat your use as acceptance of the updated Terms. If a modification is unacceptable to you, you may cease using the Services and deactivate your account if applicable.
</p>


<h2>9. Disclaimer of Warranties</h2>

<p><br/>Services Provided "As Is":<br/>
You expressly understand and agree that your use of the Kupeel services is at your sole risk and that the services are provided "as is" and "as available." Kupeel, its subsidiaries, affiliates, and its licensors do not represent or warrant to you that:
<br/>- Your use of the services will meet your requirements,
<br/>- Your use of the services will be uninterrupted, timely, secure, or free from error,
<br/>- Any information obtained by you as a result of your use of the services will be accurate or reliable, and
<br/>- That defects in the operation or functionality of any software provided to you as part of the services will be corrected.
<br/>
No Other Warranties:<br/>
No advice or information, whether oral or written, obtained by you from Kupeel or through or from the services shall create any warranty not expressly stated in the terms. Kupeel further expressly disclaims all warranties and conditions of any kind, whether express or implied, including, but not limited to the implied warranties and conditions of merchantability, fitness for a particular purpose, and non-infringement.
<br/>
Responsibility for Loss:<br/>
Kupeel is not responsible for any direct, indirect, incidental, special consequential, or exemplary damages which may be incurred by you as a result of using our Services, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses.</p>

<h2>10. Limitation of Liability
</h2>

<p><br/>Scope of Liability:<br/>
Kupeel LTD, including its officers, directors, employees, partners, and agents, shall not be liable to you for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses (even if Kupeel has been advised of the possibility of such damages) resulting from:

<br/>- Your access to or use of or inability to access or use the services;
<br/>- <br/>- Any conduct or content of any third party on the services, including without limitation, any defamatory, offensive, or illegal conduct of other users or third parties;
Any content obtained from the services;
<br/>- Unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.

<br/>
Exceptions to Limitation of Liability:<br/>
The laws of certain jurisdictions, including the UK and the European Union, do not allow the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or limitations may not apply to you, and you might have additional rights.

</p>

<h2>11. General Conditions</h2>

<p>Interpretation:
In these Terms and Conditions, the words "including" and "include" mean "including, but not limited to." The headings in these Terms are for convenience only and do not affect the interpretation of these Terms.

<br/>Severability:<br/>
If any provision (or part of a provision) of these Terms and Conditions is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable, or illegal, the other provisions shall remain in force. If any invalid, unenforceable, or illegal provision would be valid, enforceable, and legal if some part of it were deleted, the provision shall apply with whatever modification is necessary to give effect to the commercial intention of the parties.

<br/>Entire Agreement:<br/>
These Terms and Conditions constitute the entire agreement between you and Kupeel LTD regarding your use of the Services and supersede all prior agreements and understandings between you and Kupeel LTD.

<br/>Waiver:<br/>
No failure or delay by Kupeel LTD in exercising any right, power, or privilege under these Terms and Conditions shall operate as a waiver, nor shall any single or partial exercise of any right, power, or privilege preclude any other or further exercise thereof or the exercise of any other right, power, or privilege.

<br/>Assignment:<br/>
You may not assign these Terms and Conditions or any rights or obligations contained herein. Kupeel LTD may, without restriction, assign these Terms and Conditions or any rights or obligations contained herein to any entity or individual.

<br/>Force Majeure:<br/>
Kupeel shall not be liable for any failure to perform its obligations hereunder where such failure results from any cause beyond Kupeel's reasonable control, including, without limitation, mechanical, electronic, or communications failure or degradation.

<br/>Survival:<br/>
The sections of these Terms and Conditions that by their nature should survive termination will survive termination, including, without limitation, accrued rights to payment, warranty disclaimers, and limitations of liability.

</p>

<h2>12. Contact Us</h2>

<p>If you have any questions about these Terms and Conditions, our Services, or any other issues related to your use of Kupeel, please do not hesitate to contact us. We are committed to providing you with excellent service and support.<br/>

Contact Information:<br/>

Email: For all inquiries, please email us at julien@bardin.me.<br/>
Address: You can also reach us by mail at Kupeel LTD, 55 Marchmont Street, WC1N 1AP, London, England. Please note that responses may be slower via mail.<br/>
We encourage you to contact us directly with any questions or concerns regarding your experience with Kupeel. Our dedicated team is here to help ensure your interactions with our service are successful and enjoyable.


</p>

<h2>
13. Ending and Agreement
</h2>

<p>


By using the services provided by Kupeel, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you should discontinue use of Kupeel's services immediately.<br/>

We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.<br/>

Your continued use of the Services after any changes to these Terms will constitute your acceptance of such changes. If you do not agree to the new terms, please stop using the Services.<br/>

Thank you for choosing Kupeel. We look forward to helping you create and enjoy innovative audio content.

</p>


       </div>
        </div>
      </div>
    );
  }
};

export default Conditions;
