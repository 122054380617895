import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Thumbnail from './Thumbnail';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pushGTMEvent } from '../GoogleAnalytics';
import './SeriePage.css'; // Assuming you have a corresponding CSS file

const Carousel = ({ title, handlePlay, searchQuery, inCommingElements, handleClick, loading }) => {
  const { t } = useTranslation(); // Initialize useTranslation hook
  const [elements, setElements] = useState(inCommingElements);
  const [selectedElements, setSelectedElement] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState(window.innerWidth < 768 ? 2 : window.innerWidth < 1200 ? 3 : 5);

  const goToPrev = () => {
    pushGTMEvent("carousel", "click", "carousel-prev");
    setCurrentIndex(currentIndex - numberOfItems >= 0 ? currentIndex - numberOfItems : 0);
  };

  const goToNext = () => {
    pushGTMEvent("carousel", "click", "carousel-next");
    setCurrentIndex(currentIndex + numberOfItems < selectedElements.length - numberOfItems ? currentIndex + numberOfItems : selectedElements.length - numberOfItems);
  };

  function getNumberOfItems() {
    return window.innerWidth < 520 ? 2 : window.innerWidth < 1170 ? 3 : window.innerWidth < 1350 ? 4 : 5;
  }

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setNumberOfItems(getNumberOfItems());
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures effect is only run on mount and unmount

  useEffect(() => {
    setElements(inCommingElements);
  }, [inCommingElements]);

  useEffect(() => {
    setSelectedElement(elements.filter((element) => {
      if (searchQuery) {
        const showSearch = searchQuery === '' || element.title.toLowerCase().includes(searchQuery.toLowerCase());
        return showSearch && element.status === 'SUCCEEDED';
      } else {
        return element.status === 'SUCCEEDED';
      }
    }));
  }, [searchQuery, elements]);

  return (
    <section className="channel-section">
      {(selectedElements.length > 0 || loading) ? (
        <div className="carousel" style={{ overflow: "hidden" }}>
          <div className="carousel-top">
            <h2 className="carousel-title">{title}</h2>
            <div className="carousel-controls">
              <FontAwesomeIcon
                onClick={goToPrev}
                style={{ color: (currentIndex === 0) ? "grey" : "black", cursor: "pointer" }}
                className="menu-icon"
                icon={faChevronLeft}
              />
              <FontAwesomeIcon
                onClick={goToNext}
                style={{ color: (currentIndex >= selectedElements.length - numberOfItems) ? "grey" : "black", cursor: "pointer" }}
                className="menu-icon"
                icon={faChevronRight}
              />
            </div>
          </div>
          <div className="carousel-inner">
            {loading ? (
              <div className="spinner-container">
                <div className="spinner"></div>
                <div className="loading-text">{t('loading')}</div>
              </div>
            ) : (
              selectedElements.map((element, index) =>
                index >= currentIndex && index < (currentIndex + numberOfItems) ? (
                  <Thumbnail
                    key={index}
                    element={element}
                    onPlay={handlePlay}
                  />
                ) : null
              )
            )}
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default Carousel;
