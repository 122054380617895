import React, { useState, useContext } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import "./SubscriptionStyles.css";
import { useTranslation } from "react-i18next";
import { pushGTMEvent } from "../GoogleAnalytics";
import { DataContext } from "../DataContext"; // Import DataContext
import Loading from "./Loading";

function Subscription({ stripeIntent, setIsSubscribed, getToken }) {
  const { t } = useTranslation();
  const [message, setMessage] = useState(null);
  const { getUserSubscription, isLoadingUserSubscription } =
    useContext(DataContext); // Use DataContext
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState("free");
  const [showPaymentElement, setShowPaymentElement] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  pushGTMEvent("page_view", "Page Interaction", window.location);

  const createSubscription = async (paymentMethod) => {
    pushGTMEvent("subscription", "Subscription", "Subscription");
    const subscriptionType = selectedSubscription === "free" ? "free" : "paid";
    getToken().then((token) => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/subscription/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          payment_method_id: paymentMethod,
          customer_id: stripeIntent.customer,
          subscription_type: subscriptionType,
          plan_name: selectedSubscription,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          setMessage("Success");
          setIsProcessing(false);
          getUserSubscription(getToken, true);
          setIsSubscribed(true);
        })
        .catch((error) => {
          setMessage(error.message);
          setIsProcessing(false);
        });
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const result = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "http://localhost:3000/",
      },
    });

    if (result.error) {
      setMessage(result.error.message);
      setIsProcessing(false);
    } else {
      createSubscription(result.setupIntent.payment_method);
    }
  };

  const handleSubscriptionSelect = (subscription) => {
    setSelectedSubscription(subscription);
  };

  const handleContinue = () => {
    if (selectedSubscription === "free") {
      setIsProcessing(true);
      createSubscription(null) // Call createSubscription with null as no payment method is needed
        .then(() => {
          setMessage(t("freeSubscriptionActivated"));
          setIsSubscribed(true);
        })
        .catch((error) => {
          setMessage(error.message);
          setIsProcessing(false);
        });
    } else {
      setShowPaymentElement(true);
    }
  };

  const handleBack = () => {
    setShowPaymentElement(false);
  };

  return (
    <div
      className={
        showPaymentElement ? "container-payment-payment" : "container-payment"
      }
    >
      {!showPaymentElement ? (
        <div className="subscription-selection">
          <div className="subscription-cards">
            <div
              className={`subscription-card ${
                selectedSubscription === "free" ? "selected" : ""
              }`}
              onClick={() => handleSubscriptionSelect("free")}
            >
              <h3>Free</h3>
              <div className="subscription-detail">
                <div className="subscription-box first-box">
                  <a className="content-box title-box">Free</a>
                  <a className="content-box">No generation available</a>
                  <a className="content-box">Listen to thousands of episodes</a>
                  <a className="content-box">Daily episodes generated</a>
                </div>
              </div>
            </div>
            <div
              className={`subscription-card ${
                selectedSubscription === "pro" ? "selected" : ""
              }`}
              onClick={() => handleSubscriptionSelect("pro")}
            >
              <h3>Pro</h3>
              <div className="subscription-detail">
                <div className="subscription-box first-box">
                  <a className="content-box title-box">20£/m</a>
                  <a className="content-box">Non-premium voices only</a>
                  <a className="content-box">40 mins of generation per month</a>
                  <a className="content-box">Public access available</a>
                </div>
              </div>
            </div>
            <div
              className={`subscription-card ${
                selectedSubscription === "studio" ? "selected" : ""
              }`}
              onClick={() => handleSubscriptionSelect("studio")}
            >
              <h3>Studio</h3>
              <div className="subscription-detail">
                <div className="subscription-box first-box">
                  <a className="content-box title-box">330£/m</a>
                  <a className="content-box">Premium voices</a>
                  <a className="content-box">5 hours (300 min) of generation</a>
                  <a className="content-box">Public access available</a>
                  <a className="content-box">Multi language generation</a>
                  <a className="content-box">
                    (soon) RSS feed and deployment on other platforms
                  </a>
                  <a className="content-box">
                    (soon) Personalization of the public page.
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button onClick={handleContinue} className="continue-button">
            {selectedSubscription === "free" ? t("confirm") : t("continue")}
          </button>
        </div>
      ) : (
        <form className="payment-form" onSubmit={handleSubmit}>
          <h2 className="payment-form-title" id="baseSelectorTitle">
            {t("paymentDetails")}
          </h2>
          <PaymentElement className="payment-element" id="payment-element" />
          <button type="submit" disabled={isProcessing || !stripe || !elements}>
            {t("confirm")}
          </button>
          <button
            type="button"
            onClick={handleBack}
            className="back-button"
            disabled={isProcessing || !stripe || !elements}
          >
            {t("back")}
          </button>
          {message && <div id="messages-payment">{message}</div>}
        </form>
      )}
    </div>
  );
}

export default Subscription;
