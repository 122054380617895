import React, { useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import MainApp from './MainApp';
import SeriePlayer from './extraplayer/components/SeriePlayer';
import { HashRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import awsExports from "./aws-exports";
import { Amplify } from 'aws-amplify';
import EmbeddedEpisode from './EmbeddedEpisode';
import EmbeddedSerie from './EmbeddedSerie';
import { DataProvider } from './DataContext';
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';
import Conditions from './Conditions';
import { GoogleAnalytics } from './GoogleAnalytics';
import Redirect from "./Redirect"
import PublicProfile from './extraplayer/components/PublicProfile';
import './i18n';  // Import i18n

Amplify.configure(awsExports);

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const scope = process.env.REACT_APP_AUTH0_SCOPE;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const GTM_ID = 'GTM-WFGC7Q8T';

function useGTM(gtmId) {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${gtmId}');
    `;
    document.head.appendChild(script);
  }, [gtmId]);
}

const KupeelApp = () => {
  const browserLanguage = navigator.language;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
        ignoreCache: true,
        scope: scope,
        ui_locales: browserLanguage
      }}
      ui_locales={ browserLanguage}
      audience={audience}
      scope={scope}
      useRefreshTokens={true}
    >
        <MainApp />
        <GoogleAnalytics />
        <AddToHomeScreen />
    </Auth0Provider>
  );
};

function App() {
  useGTM(GTM_ID);

  useEffect(() => {
    const handleRouteChange = () => {
      window.dataLayer.push({
        event: 'pageview',
        page: window.location.pathname
      });
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  return (
  <DataProvider>
    <Router>
      <Routes>
        <Route path="/conditions" element={<Conditions />} />
        {/* <Route path="/app/public/content/:id" element={<SeriePlayer />} /> */}
        <Route path="/app/public/content/:serieId" element={<SeriePlayer />} />
        <Route path="/app/public/content/:serieId/:episodeId" element={<SeriePlayer />} />
        <Route path="/app/public/profile/:profileId" element={<PublicProfile />} />
        <Route path="/redirect/:id" element={<Redirect />} />
        <Route path="/" element={<Navigate replace to="/app" />} />
        <Route path="/app/*" element={<KupeelApp />} />
      </Routes>
    </Router>
    </DataProvider>
  );
}

export default App;
