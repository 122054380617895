import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Ensure you point to the correct CSS file
import { useTranslation } from 'react-i18next';

const LoginPage = ({ isAuthenticated, loginWithRedirect }) => {
  const { t } = useTranslation();
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const navigate = useNavigate();
  const browserLanguage = navigator.language;
  console.log("Browser language: ", browserLanguage);

  const rotatingTexts = [
    t('transform_ideas'),
    t('join_crowd_version'),
    t('discover_content'),
    t('your_voice_platform'),
    t('support_and_grow')
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % rotatingTexts.length);
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  if (!isAuthenticated) {
    return (
      <div className="login-container">
        <div className="login-cardPack">
          <div className="login-card">
            <img src="/kupeel-logo.png" alt="Logo" className="login-logo" />
            <p>{t('accept_terms')}<a onClick={() => { navigate("/conditions") }} style={{ "color": "#89023E", "cursor": "pointer" }}>{t('terms_conditions')}</a></p>
            <button id="login" onClick={() => loginWithRedirect({
              authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                ui_locales: browserLanguage,
                ignoreCache: true,
                scope: process.env.REACT_APP_AUTH0_SCOPE,
              },
              audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              scope: process.env.REACT_APP_AUTH0_SCOPE,
              ui_locales: browserLanguage,
              prompt: 'consent'
            })} className="big-button-lato">
              {t('sign_in_up')}
            </button>
          </div>
          <div className="login-rotatingText">
            <h2 id="baseSelectorTitle">{t('crowd_version')}</h2>
            <img src="login1.jpg" className="login-imgSide" />
            <audio controls src="audio.mp3" style={{ width: '100%' }}>
              {t('audio_not_supported')}
            </audio>
            <p>{rotatingTexts[currentTextIndex]}</p>
          </div>
        </div>
      </div>
    );
  }
};

export default LoginPage;
