// PublicProfile.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './PublicProfile.css'; // Import the CSS file for styling
import Loading from '../../components/Loading'; // Import the Loading component
import { getDefaultLanguage } from '../../components/Fetching'; // Import the getDefaultLanguage function
import Credit from "./Credit";
import SupportButton from './SupportButton'; // Import the SupportButton component

const PublicProfile = () => {
    const { t } = useTranslation();
    const { profileId } = useParams();
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [series, setSeries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Mock data
    const mockProfile = {
        img: 'mockProfileImage.png',
        name: 'Mock User',
        desc: 'This is a mock user description.',
        supportLinkTitle: 'Support Me',
        supportLinkPayment: 'https://example.com/support'
    };

    const mockSeries = [
        {
            _id: '1',
            title: 'Mock Series 1',
            img: 'mockImage1.png',
            description: 'This is a mock series 1 description.'
        },
        {
            _id: '2',
            title: 'Mock Series 2',
            img: 'mockImage2.png',
            description: 'This is a mock series 2 description.'
        }
    ];

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                // Simulate a condition to use mock data
                const useMockData = false; // Change this to true to use mock data

                if (useMockData) {
                    setProfile(mockProfile);
                    setSeries(mockSeries.map((serie) => ({
                        ...serie,
                        type: "serie",
                        id: serie._id,
                        title: serie.title || serie._id || "unknown",
                        imageUrl: serie.img || 'kupeel2.png',
                        channel: 'kupeel',
                        audio: 1,
                        status: 'SUCCEEDED'
                    })));
                } else {
                    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/content/${profileId}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`, // Adjust this based on how you store tokens
                            'Accept-Language': getDefaultLanguage() || "en"
                        }
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();
                    setProfile(data? data.profile : null);
                    // console.log("data: ", data)
                    setSeries(data.series.map((serie) => ({
                        ...serie,
                        type: "serie",
                        id: serie._id,
                        title: serie.title || serie._id || "unknown",
                        imageUrl: serie.img || 'kupeel2.png',
                        channel: 'kupeel',
                        audio: 1,
                        status: 'SUCCEEDED'
                    })));
                }

                setIsLoading(false);
            } catch (err) {
                console.error('Error fetching profile data:', err);
                setIsLoading(false);
            }
        };

        fetchProfileData();
    }, [profileId]);

    const handleSeriesClick = (serieId) => {
        navigate(`/app/public/content/${serieId}`);
    };

    if (isLoading) {
        return <Loading />;
    }

    if (!profile) {
        return <div>{t('profileNotFound')}</div>;
    }

    return (
        <>
            <div className="public-profile-container">
                <div className="profile-header">
                    <img src={profile.img} alt={profile.name} className="profile-image" />
                    <h1 className="profile-name">{profile.name}</h1>
                    <p className="profile-description">{profile.desc}</p>
                    <SupportButton 
                        supportLinkTitle={profile.supportLinkTitle} 
                        supportLinkPayment={profile.supportLinkPayment} 
                    />
                </div>
                <div className="series-list">
                    <h2>{t('userSeries')}</h2>
                    {series.length > 0 ? (
                        <ul>
                            {series.map((serie) => (
                                <li key={serie.id} className="series-item" onClick={() => handleSeriesClick(serie.id)}>
                                    <img src={serie.imageUrl} alt={serie.title} className="series-image" />
                                    <div className="series-info">
                                        <h3>{serie.title}</h3>
                                        <p>{serie.description}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>{t('noSeriesFound')}</p>
                    )}
                </div>
            </div>
            <Credit profile={profile != undefined ? profile: {}}/>
        </>
    );
};

export default PublicProfile;
