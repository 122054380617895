import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './TopicCarousel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { pushGTMEvent } from '../GoogleAnalytics';
import { useTranslation } from 'react-i18next';

const topics = [
  { "name": "Science", "color": "#FFCAD4" },
  { "name": "Technology", "color": "#B0D0D3" },
  { "name": "Art", "color": "#C08497" },
  { "name": "History", "color": "#9E7C98" },
  { "name": "Mathematics", "color": "#ACBFA4" },
  { "name": "Literature", "color": "#E2E8CE" },
  { "name": "Music", "color": "#BFD7EA" },
  { "name": "Philosophy", "color": "#298B92" },
  { "name": "Sports", "color": "#FFCACE" },
  { "name": "Astronomy", "color": "#C08497" },
  { "name": "Biology", "color": "#D4D2A5" },
  { "name": "Chemistry", "color": "#8A94AD" },
  { "name": "Physics", "color": "#2292A4" },
  { "name": "Economics", "color": "#EEE0CB" },
  { "name": "Geography", "color": "#BFD7EA" },
  { "name": "Politics", "color": "#93A788" },
  { "name": "Psychology", "color": "#EFCA08" },
  { "name": "Languages", "color": "#8D6A9F" },
  { "name": "Engineering", "color": "#00A6A6" },
  { "name": "Medicine", "color": "#BBDEF0" },
  { "name": "Sociology", "color": "#989163" },
  { "name": "Environment", "color": "#EEB5B9" },
  { "name": "Architecture", "color": "#A76F5A" },
  { "name": "Fashion", "color": "#A56B74" },
  { "name": "Culinary Arts", "color": "#E8E9EB" },
  { "name": "Film & Cinema", "color": "#E0DFD5" },
  { "name": "Theatre", "color": "#9D6D54" },
  { "name": "Dance", "color": "#8E7DBE" },
  { "name": "Photography", "color": "#8E7DBE" },
  { "name": "Gardening", "color": "#A4619F" },
  { "name": "Travel & Tourism", "color": "#EAF27C" },
  { "name": "Entrepreneurship", "color": "#FFCAD4" },
  { "name": "Gaming", "color": "#B0D0D3" },
  { "name": "Virtual Reality", "color": "#C08497" },
  { "name": "Robotics", "color": "#8E6C88" },
  { "name": "Artificial Intelligence", "color": "#ACBFA4" },
  { "name": "Sustainability", "color": "#E2E8CE" },
  { "name": "Space Exploration", "color": "#9D6D54" },
  { "name": "Marine Biology", "color": "#49ABB2" },
  { "name": "Meteorology", "color": "#FFBABE" },
  { "name": "Archaeology", "color": "#8E8798" },
  { "name": "Anthropology", "color": "#D4D2A5" },
  { "name": "Cryptography", "color": "#6A849D" },
  { "name": "Quantum Computing", "color": "#2292A4" },
  { "name": "Neuroscience", "color": "#EEE0CB" },
  { "name": "Forensic Science", "color": "#BFD7EA" },
  { "name": "Nanotechnology", "color": "#839788" },
  { "name": "Urban Planning", "color": "#EFCA08" }
];

const TopicCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 8; // 2 rows * 4 columns
  const [numberOfItems, setNumberOfItems] = useState(getNumberOfItems());
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedElements, setSelectedElement] = useState(topics);

  pushGTMEvent("topic-carousel", "loaded", "topic-carousel-loaded");

  const goToPrev = () => {
    pushGTMEvent("topic-carousel", "click", "topic-carousel-prev");
    setCurrentIndex(currentIndex - itemsPerPage >= 0 ? currentIndex - itemsPerPage : 0);
  };

  const goToNext = () => {
    pushGTMEvent("topic-carousel", "click", "topic-carousel-next");
    setCurrentIndex(currentIndex + itemsPerPage < topics.length ? currentIndex + itemsPerPage : currentIndex);
  };

  const handleTopicClick = (topic) => {
    pushGTMEvent("topic-carousel", "click", `topic-carousel-${topic}`);
    navigate(`/app/topic/${topic}`);
  };

  function getNumberOfItems() {
    return window.innerWidth < 520 ? 2 : window.innerWidth < 1170 ? 3 : window.innerWidth < 1350 ? 4 : 5;
  }

  const visibleTopics = topics.slice(currentIndex, currentIndex + itemsPerPage);

  return (
    <section className="topic-carousel">
      <div className="carousel" style={{ overflow: "hidden" }}>
        <div className="carousel-top" style={{}}>
          <h2 className="carousel-title">{t('topics')}</h2>
          <div className="carousel-controls">
            <FontAwesomeIcon onClick={goToPrev} style={{ color: (currentIndex === 0) ? "grey" : "black", cursor: "pointer" }} className="menu-icon" icon={faChevronLeft} />
            <FontAwesomeIcon onClick={goToNext} style={{ color: (currentIndex >= selectedElements.length - numberOfItems) ? "grey" : "black", cursor: "pointer" }} className="menu-icon" icon={faChevronRight} />
          </div>
        </div>
        <div className="carousel-inner" style={{}}>
          {visibleTopics.map((topic, index) => (
            <div
              key={index}
              className="topic-item"
              onClick={() => handleTopicClick(topic.name)}
              style={{ backgroundColor: topic.color }}>
              {t(topic.name)}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TopicCarousel;
