import React, { createContext, useState } from 'react';
import { fetchAllMyContent, fetchSeries, fetchEpisodes, fetchTrending } from './components/Fetching';

export const DataContext = createContext();

function extractLanguage(acceptLanguage) {
  if (!acceptLanguage) {
      return acceptLanguage;
  }
  // Split the input by comma to handle cases where there are multiple languages
  const languages = acceptLanguage.split(',');

  // Map each language tag to its ISO language code
  const isoLanguages = languages.map(lang => {
      // Extract the part before the first hyphen or semicolon
      const isoCode = lang.split('-')[0].split(';')[0].trim();
      return isoCode;
  });

  // Return the first ISO language code, or an array of all ISO codes if you prefer
  return isoLanguages[0];
}

export const DataProvider = ({ children }) => {
  const [episodes, setEpisodes] = useState([]);
  const [series, setSeries] = useState([]);
  const [trending, setTrending] = useState([]);
  const [myEpisodes, setMyEpisodes] = useState([]);
  const [mySeries, setMySeries] = useState([]);
  const [voices, setVoices] = useState([
    {
      "_id": "660594db5f3cc96b0218af26",
      "name": "Bethany",
      "category": "premade",
      "labels": {
        "accent": "american",
        "age": "middle aged",
        "gender": "female",
        "usecase": "scientific"
      },
      "description": "",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
      "settings": null,
      "id": "alloy",
      "premium": false
    },
    {
      "_id": "660594db5f3cc96b0218af27",
      "name": "Josh",
      "category": "premade",
      "labels": {
        "accent": "american",
        "age": "middle aged",
        "gender": "male",
        "usecase": "podcast"
      },
      "description": "",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
      "settings": null,
      "id": "echo",
      "premium": false
    },
    {
      "_id": "660594db5f3cc96b0218af28",
      "name": "Thomas",
      "category": "premade",
      "labels": {
        "accent": "english",
        "age": "middle aged",
        "gender": "male",
        "usecase": "podcast"
      },
      "description": "",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
      "settings": null,
      "id": "fable",
      "premium": false
    },
    {
      "_id": "660594db5f3cc96b0218af29",
      "name": "Leo",
      "category": "premade",
      "labels": {
        "accent": "english",
        "age": "middle aged",
        "gender": "male",
        "usecase": "audioguide"
      },
      "description": "",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
      "settings": null,
      "id": "echo",
      "premium": false
    },
    {
      "_id": "660594db5f3cc96b0218af2a",
      "name": "Michael",
      "category": "premade",
      "labels": {
        "accent": "american",
        "age": "mature",
        "gender": "male",
        "usecase": "audioguide"
      },
      "description": "",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
      "settings": null,
      "id": "onyx",
      "premium": false
    },
    {
      "_id": "660594db5f3cc96b0218af2b",
      "name": "Carmen",
      "category": "premade",
      "labels": {
        "accent": "american",
        "age": "middle aged",
        "gender": "female",
        "usecase": "audiobook"
      },
      "description": "",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
      "settings": null,
      "id": "nova",
      "premium": false
    },
    {
      "_id": "660594db5f3cc96b0218af2c",
      "name": "Jessy",
      "category": "premade",
      "labels": {
        "accent": "american",
        "age": "young",
        "gender": "female",
        "usecase": "news"
      },
      "description": "",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
      "settings": null,
      "id": "shimmer",
      "premium": false
    },
    {
      "_id": "653286775739d1426af46b13",
      "name": "Josh",
      "category": "premade",
      "labels": {
        "accent": "american",
        "age": "young",
        "gender": "male",
        "usecase": "narration"
      },
      "description": "deep",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3",
      "settings": null,
      "id": "TxGEqnHWrfWFTfGW9XjX",
      "premium": true
    },
    {
      "_id": "653286775739d1426af46b03",
      "name": "Rachel",
      "category": "premade",
      "labels": {
        "accent": "american",
        "age": "young",
        "gender": "female",
        "usecase": "narration"
      },
      "description": "calm",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/df6788f9-5c96-470d-8312-aab3b3d8f50a.mp3",
      "settings": null,
      "id": "21m00Tcm4TlvDq8ikWAM",
      "premium": true
    },
    {
      "_id": "653286775739d1426af46b2a",
      "name": "Varvara",
      "category": "cloned",
      "labels": {
        "usecase": null,
        "age": null,
        "accent": null,
        "gender": null
      },
      "description": "varavara's voice",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/AEZexRTceyPIfr5KZquDT4uHlus1/voices/MZUEqzjJhpmguYXgf5oY/b7cc14da-2984-4f39-87ae-041518a488d9.mp3",
      "settings": null,
      "id": "MZUEqzjJhpmguYXgf5oY",
      "premium": true
    },
    {
      "_id": "653286775739d1426af46b0b",
      "name": "Charlie",
      "category": "premade",
      "labels": {
        "accent": "australian",
        "age": "middle aged",
        "gender": "male",
        "usecase": "conversational"
      },
      "description": "casual",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/IKne3meq5aSn9XLyUdCD/102de6f2-22ed-43e0-a1f1-111fa75c5481.mp3",
      "settings": null,
      "id": "IKne3meq5aSn9XLyUdCD",
      "premium": true
    },
    {
      "_id": "653286775739d1426af46b16",
      "name": "Matilda",
      "category": "premade",
      "labels": {
        "accent": "american",
        "age": "young",
        "gender": "female",
        "usecase": "audiobook"
      },
      "description": "warm",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/XrExE9yKIg1WjnnlVkGX/b930e18d-6b4d-466e-bab2-0ae97c6d8535.mp3",
      "settings": null,
      "id": "XrExE9yKIg1WjnnlVkGX",
      "premium": true
    },
    {
      "_id": "653286775739d1426af46b08",
      "name": "Bella",
      "category": "premade",
      "labels": {
        "accent": "american",
        "age": "young",
        "gender": "female",
        "usecase": "narration"
      },
      "description": "soft",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/941b779e-c2ad-48d4-bddb-28d1a68fa27e.mp3",
      "settings": null,
      "id": "EXAVITQu4vr4xnSDxMaL",
      "premium": true
    },
    {
      "_id": "660304c6af1534b989aee593",
      "id": "09AoN6tYyW3VSTQqCo7C",
      "name": "Jessi",
      "category": "professional",
      "labels": {
        "language": "en",
        "descriptive": "pleasant",
        "accent": "american",
        "gender": "female",
        "age": "young",
        "use_case": "narrative_story"
      },
      "description": "A pleasant mid-20s American female voice. Confident and casual. Perfect for news, corporate videos, faceless videos, audio narration, and more.",
      "premium": true
    },
    {
      "_id": "660304c6af1534b989aee594",
      "name": "Burt",
      "category": "professional",
      "labels": {
        "language": "en",
        "descriptive": "calm",
        "accent": "american",
        "gender": "male",
        "age": "young"
      },
      "description": "Middle-aged Asian American male. Good for narration or as a character voice.",
      "id": "kdVjFjOXaqExaDvXZECX",
      "premium": true
    },
    {
      "_id": "660304c6af1534b989aee595",
      "name": "Kayla",
      "category": "professional",
      "labels": {
        "language": "en",
        "descriptive": "casual",
        "accent": "African American",
        "gender": "female",
        "age": "young",
        "use_case": "conversational"
      },
      "description": "An urban American female voice, ideal for casual conversations, podcasts, and audiobooks.",
      "id": "aTxZrSrp47xsP6Ot4Kgd",
      "premium": true
    },
    {
      "_id": "660304c6af1534b989aee596",
      "name": "Armando",
      "category": "professional",
      "labels": {
        "language": "en",
        "descriptive": "casual",
        "accent": "american",
        "gender": "male",
        "age": "young",
        "use_case": "conversational"
      },
      "description": "A young American 24-year-old with a slight hoarseness in his throat. Voice works well for conversational content.",
      "id": "TWUKKXAylkYxxlPe4gx0",
      "premium": true
    },
    {
      "_id": "660304c6af1534b989aee597",
      "name": "Arabella",
      "category": "professional",
      "labels": {
        "language": "en",
        "descriptive": "raspy",
        "accent": "australian",
        "gender": "female",
        "age": "young",
        "use_case": "conversational"
      },
      "description": "A young engaging female voice, enchanting and compelling with every word. Perfect for audiobooks, storytelling, and narration.",
      "id": "aEO01A4wXwd1O8GPgGlF",
      "premium": true
    },
    {
      "_id": "660307bdaf1534b989aee598",
      "name": "Alice",
      "samples": null,
      "category": "premade",
      "labels": {
        "accent": "british",
        "description": "confident",
        "age": "middle aged",
        "gender": "female",
        "featured": "new",
        "use case": "news"
      },
      "description": null,
      "id": "Xb7hH8MSUJpSbSDYk0k2",
      "premium": true
    },
    {
      "_id": "660307bdaf1534b989aee599",
      "name": "Bill",
      "samples": null,
      "labels": {
        "accent": "american",
        "description": "strong",
        "age": "middle aged",
        "gender": "male",
        "use case": "documentary"
      },
      "description": null,
      "id": "pqHfZKP75CvOlQylNhV4",
      "premium": true
    },
    {
      "_id": "660307bdaf1534b989aee59a",
      "name": "Drew",
      "samples": null,
      "category": "premade",
      "labels": {
        "accent": "american",
        "description": "well-rounded",
        "age": "middle aged",
        "gender": "male",
        "use case": "news"
      },
      "description": null,
      "id": "29vD33N1CtxCmqQRPOHJ",
      "premium": true
    },
    {
      "_id": "660307bdaf1534b989aee59b",
      "name": "George",
      "samples": null,
      "category": "premade",
      "labels": {
        "accent": "british",
        "description": "raspy",
        "age": "middle aged",
        "gender": "male",
        "use case": "narration"
      },
      "description": null,
      "id": "JBFqnCBsd6RMkjVDRZzb",
      "premium": true
    },
    {
      "_id": "660307bdaf1534b989aee59c",
      "name": "Lily",
      "samples": null,
      "category": "premade",
      "labels": {
        "accent": "british",
        "description": "raspy",
        "age": "middle aged",
        "gender": "female",
        "use case": "narration"
      },
      "description": null,
      "id": "pFZP5JQG7iQjIQuC4Bku",
      "premium": true
    },
    {
      "_id": "660307bdaf1534b989aee59d",
      "name": "Paul",
      "samples": null,
      "category": "premade",
      "labels": {
        "accent": "american",
        "description": "ground reporter",
        "age": "middle aged",
        "gender": "male",
        "use case": "news"
      },
      "description": null,
      "id": "5Q0t7uMcjvnagumLfvZi",
      "premium": true
    }
  ]);
  const [gif, setGif] = useState([]);
  const [userDetails, setUserDetails] = useState({}); // State for user details
  const [userSubscription, setUserSubscription] = useState(undefined); // State for user details
  const [language, setLanguageState] = useState(extractLanguage(localStorage.getItem('language')) || extractLanguage(navigator.language) || 'en');

  // Add loading states
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [isLoadingVoices, setIsLoadingVoices] = useState(false);
  const [isLoadingMyContent, setIsLoadingMyContent] = useState(false);
  const [isLoadingUserDetails, setIsLoadingUserDetails] = useState(false); // Loading state for user details
  const [isLoadingUserSubscription, setIsLoadingUserSubscription] = useState(true); // Loading state for user details

  const loadContent = async (getToken, force = false) => {
    if (!force && (isLoadingContent || series.length > 0 || episodes.length > 0)) return;
    setIsLoadingContent(true);

    try {
      const token = await getToken();
      const episodesData = await fetchEpisodes(token, language);
      setEpisodes(episodesData);
      const seriesData = await fetchSeries(token, language);
      setSeries(seriesData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingContent(false);
    }
  };

  const loadVoices = async (getToken, force = false) => {
    // if (!force && (isLoadingVoices || voices.length > 0)) return;
    // setIsLoadingVoices(true);

    // try {
    //   const token = await getToken();
    //   const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/voice/all`, {
    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       "Authorization": `Bearer ${token}`,
    //       'Accept-Language': language,
    //     },
    //   });

    //   if (!response.ok) {
    //     throw new Error('Network response was not ok');
    //   }

    //   const data = await response.json();
    //   setVoices(data.voices.map(voice => ({ ...voice })));
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setIsLoadingVoices(false);
    // }
  };

  const loadTrending = async (getToken, force = false) => {
    if (!force && trending.length > 0) return trending;
    try {
      const token = await getToken();
      const data = await fetchTrending(token, language);
      setTrending(data);
    } catch (error) {
      console.error(error);
    }
  };

  const loadMyContent = async (getToken, force = false) => {
    if (!force && (isLoadingMyContent || mySeries.length > 0 || myEpisodes.length > 0)) return;
    setIsLoadingMyContent(true);

    try {
      const token = await getToken();
      const data = await fetchAllMyContent(token, language);
      setMyEpisodes(data.episodes);
      setMySeries(data.series);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMyContent(false);
    }
  };

  const getGif = async (force = false) => {
    if (!force && gif.length > 0) return gif;
    var randomNumber = Math.random();
    randomNumber = Math.floor(randomNumber * 21);
    setGif("/gif/wait/wait" + randomNumber + ".gif");
    return gif;
  };

  const getUserDetails = async (getToken) => {
    setIsLoadingUserDetails(true); // Set loading state to true
    try {
      const token = await getToken();
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/subscription/limit`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Accept-Language': language,
        },
      });

      console.log(response);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setUserDetails(data); // Update the userDetails state with the fetched data
      setIsLoadingUserDetails(false);
      return data; // Return the fetched data
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUserDetails(false); // Set loading state back to false
    }
  };

  const getUserSubscription = async (getToken, forceLoad=false) => {
    console.log("getUserSubscription")
    console.log(userSubscription)
    if(userSubscription != undefined && !forceLoad) return userSubscription;
    setIsLoadingUserSubscription(true); // Set loading state to true
    try {
      const token = await getToken();
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/subscription/exists`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Accept-Language': language,
        },
      });

      console.log(response);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setUserSubscription(data); // Update the userSubscription state with the fetched data
      console.log(data);
      setIsLoadingUserSubscription(false);
      return data; // Return the fetched data
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingUserSubscription(false); // Set loading state back to false
    }
  };

  const setLanguage = (newLanguage) => {
    var languageToUse = extractLanguage(newLanguage);
    localStorage.setItem('language', languageToUse);
    setLanguageState(languageToUse);
    setEpisodes([]);
    setSeries([]);
    setTrending([]);
    setMyEpisodes([]);
    setMySeries([]);
  };
  

  return (
    <DataContext.Provider value={{
      episodes,
      series,
      mySeries,
      myEpisodes,
      loadContent,
      userSubscription,
      getUserSubscription,
      isLoadingUserSubscription,
      trending,
      loadTrending,
      loadMyContent,
      loadVoices,
      voices,
      getGif,
      isLoadingContent,
      isLoadingMyContent,
      gif,
      getUserDetails,
      userDetails,
      isLoadingUserDetails,
      language, // Provide the language in the context
      setLanguage, // Provide a way to change the language
    }}>
      {children}
    </DataContext.Provider>
  );
};
