import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Generate.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneLines, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { pushGTMEvent } from '../GoogleAnalytics';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { languageOptions } from './LanguageOptions';

function GenerateSeries({ getToken }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [audioStyle, setAudioStyle] = useState("podcasts");

  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("en");
  const [additionalLanguages, setAdditionalLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [generateImg, setGenerateImg] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const styleDescriptions = {
    audiobooks: "Audio version of the book in the title focusing on the facts and the ideas of the author. Keeping its writing style in the audio speech.",
    podcasts: "Interesting, fun and full of facts podcast.",
    lectures: "Educational and informative delivery of the content in a lecture style.",
    storytelling: "Narrative-driven and engaging retelling of a book's story with facts and explanations, or creation of a full on story.",
    audioguides: "Step-by-step audio guide providing detailed insights and explanations to feel really immerse in the audioguide."
  };

  useEffect(() => {
    
    if (!description) {
      setDescription(styleDescriptions[audioStyle]);
    } else {
      for (let key in styleDescriptions) {
        if (styleDescriptions[key] === description) {
          setDescription(styleDescriptions[audioStyle]);
          break;
        }
      }
    }
    
  }, [audioStyle]);

  const additionalLanguageOptions = languageOptions.filter(option => option.value !== language);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };
    reader.readAsDataURL(file);
  };


  const createSeries = async () => {
    if (!name || (generateImg && !uploadedImage)) {
      window.alert(t('fillNameAndDescription'));
      return;
    }
    setIsLoading(true);

    try {
      const token = await getToken();
      const payload = {
        title: name,
        desc: description,
        style: audioStyle,
        language: language,
        additional_languages: additionalLanguages.map(lang => lang.value),
        img: generateImg ? uploadedImage : undefined,
        accessToken: token
      };

      await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/series/create`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((response) => {
        setIsLoading(false);
        navigate('/app/content/serie/' + response.data.object[0]._id);
      }).catch((error) => {
        setIsLoading(false);
        console.error("Error creating series:", error);
        alert(t('errorCreatingSeries'));
      });
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating series:", error);
      alert(t('errorCreatingSeries'));
    }
  };

  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
        <div className="loading-text">{t('loading')}</div>
      </div>
    );
  }

  return (
    <div className="content-package" id="generator">
      <h1 id="baseSelectorTitle">{t('createYourSeries')}</h1>
      <p style={{ fontWeight: "500" }}>{t('seriesDescription')}</p>


      <p className="form-label">{t('selectStyle')}</p>
      <select value={audioStyle || ''} onChange={(e) => setAudioStyle(e.target.value)}>
        <option value="podcasts">{t('podcasts')}</option>
        <option value="audiobooks">{t('audiobooks')}</option>
        <option value="lectures">{t('lectures')}</option>
        <option value="storytelling">{t('storytelling')}</option>
        <option value="audioguides">{t('audioguides')}</option>
      </select>

      <p className="form-label">{t('enterSeriesName')}</p>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder={t('enterSeriesName')}
      />


      <p className="form-label">{t('describeSeries')}</p>
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder={t('enterSeriesDescriptionPlaceholder')}
      />

      <p className="form-label">{t('selectLanguage')}</p>
      <select value={language} onChange={(e) => setLanguage(e.target.value)}>
        {languageOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>

      <p className="form-label">{t('selectAdditionalLanguages')}</p>
      <Select
        isMulti
        options={additionalLanguageOptions}
        onChange={setAdditionalLanguages}
        className="basic-multi-select"
        classNamePrefix="select"
      />

      <p className="form-label">{t('includeImage')}</p>
      <label className="form-generation-switch">
        <input
          type="checkbox"
          checked={generateImg}
          onChange={(e) => {
            setGenerateImg(e.target.checked);
            setUploadedImage(null); // Clear the image data when toggling off
          }}
        />
        <span className="form-generation-slider"></span>
      </label>

      {generateImg && (
        <div>
          <p className="form-label">{t('uploadImage')}</p>
          <input
            type="file"
            onChange={handleImageUpload}
            accept="image/*"
          />
        </div>
      )}

      <button onClick={createSeries} className="submit-button">{t('createSeriesButton')}</button>
    </div>
  );
}

export default GenerateSeries;
