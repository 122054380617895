import React, { useState, useEffect, useRef } from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './QrCode.css';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';

const QRCodeGenerator = ({linkProvided, btnTitle}) => {
  
  const { t } = useTranslation();
  const [link, setLink] = useState(linkProvided);
  const [qrCodeUrl, setQrCodeUrl] = useState(linkProvided);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const qrCodeRef = useRef(null);

  const handleGenerateQRCode = () => {
    setQrCodeUrl(link);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setQrCodeUrl('');
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Copied to clipboard!');
    });
  };

  const handleDownloadQRCode = () => {
    const svg = qrCodeRef.current.querySelector('svg');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(svgBlob);

    const scaleFactor = 4; // Increase this for higher resolution

    img.onload = () => {
      canvas.width = img.width * scaleFactor;
      canvas.height = img.height * scaleFactor;
      ctx.scale(scaleFactor, scaleFactor);
      ctx.drawImage(img, 0, 0);

      // Draw the logo image in the middle of the QR code
      const logo = new Image();
      logo.onload = () => {
        const logoSize = 50 * scaleFactor; // Scale the logo size accordingly
        const x = (canvas.width / (2 * scaleFactor)) - (logoSize / (2 * scaleFactor));
        const y = (canvas.height / (2 * scaleFactor)) - (logoSize / (2 * scaleFactor));
        ctx.drawImage(logo, x, y, logoSize / scaleFactor, logoSize / scaleFactor);

        // Save canvas content as PNG
        canvas.toBlob((blob) => {
          saveAs(blob, 'qrcode.png');
        }, 'image/png');
        URL.revokeObjectURL(url);
      };
      logo.src = 'kupeel-rounded.png'; // Replace with your logo path
    };
    img.src = url;
  };

  useEffect(() => {
    if (qrCodeRef.current) {
      const dots = qrCodeRef.current.querySelectorAll('rect');
      dots.forEach(dot => {
        dot.setAttribute('rx', '4');
        dot.setAttribute('ry', '4');
      });
    }
  }, [qrCodeUrl]);

  const iframeCode = `<iframe src="${qrCodeUrl}" width="300" height="300"></iframe>`;

  return (
    <div className="qr-code-generator">
      
      <button onClick={handleGenerateQRCode}> <FontAwesomeIcon icon={faQrcode} style={{marginRight:"5px"}}/>{btnTitle}</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="QR Code"
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modal-header">
        <h2>{btnTitle}</h2>
        <button onClick={closeModal} className="close-button">Close</button>
        </div>
        
        {qrCodeUrl && (
          <div ref={qrCodeRef} className="qr-code-container">
            <QRCode
              value={qrCodeUrl}
              size={256}
              bgColor="#ffffffff"
              fgColor="#FF7817" // Pink color
              level="H"
              includeMargin={true}
              renderAs="svg"
              imageSettings={{
                src: 'kupeel-rounded.png', // Replace with your logo path
                x: null,
                y: null,
                height: 50,
                width: 50,
                excavate: true,
              }}
              style={{
                borderRadius: '10px', // Rounded corners for the QR code container
              }}
            />

        <button onClick={handleDownloadQRCode}>Download QR Code</button>

          </div>
        )}
        <div className="link-container">
          <a href={qrCodeUrl} target="_blank" rel="noopener noreferrer">{qrCodeUrl}</a>
          <button onClick={() => handleCopyToClipboard(qrCodeUrl)}>{t("copyLink")}</button>
        </div>
        <div className="iframe-container">
          <code>{iframeCode}</code>
          <button onClick={() => handleCopyToClipboard(iframeCode)}>{t("copyIframeCode")}</button>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default QRCodeGenerator;
