import React, { useEffect } from 'react';

const GoogleAnalytics = () => {
  useEffect(() => {
    // Load the Google Analytics script
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}`;
    document.head.appendChild(scriptTag);

    // Setup Google Analytics
    scriptTag.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', process.env.REACT_APP_GA_ID);
    };

    return () => {
      document.head.removeChild(scriptTag);
    };
  }, []);

  return null;
};

export default GoogleAnalytics;



const pushGTMEvent = (eventName, eventCategory, eventLabel) => {
  
  
  console.log('Pushing event to dataLayer:', eventName, eventCategory, eventLabel);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
      event: eventName,
      category: eventCategory,
      label: eventLabel
    });
  };



export {pushGTMEvent, GoogleAnalytics};