import React, { useState, useEffect,  useRef } from "react";
import styled from "styled-components";
// import "./App.css";
import { useParams } from 'react-router-dom';

// Import components
import Player from "./components/otherExtraPlayer/EmbeddedEpisodePlayer";
import Song from "./components/otherExtraPlayer/EmbeddedEpisodeSong";
import Credit from "./extraplayer/components/Credit";
// Import data
// import data from "./files/oneepisode";
import { fetchAudio } from './components/Fetching'; // Adjust the path as needed

// https://fifthwrist.com/tissot-prx-quartz-review/
// https://open.spotify.com/embed/episode/2UFqmesQTgcswFJwNKKQ4V?si=8ycQ4ccJSn-bjA2USDzWcw&utm_source=oembed
// https://open.spotify.com/embed/playlist/6phYndBIC4DIqefH1CcUsT?utm_source=generator&theme=0
{/* <iframe title="kupeel Embed: DTS - Coloured Dials Battle with Colourful Characters " style="border-radius: 12px" width="100%" height="152" frameborder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" src="http://localhost:3000/public/embedded/episode/654f267e5af3cc5e2092100c"></iframe> */}
// http://localhost:3000/public/embedded/episode/654f267e5af3cc5e2092100c
const EmbeddedEpisode = () => {

    const { id } = useParams();
    const audioRef = useRef(null);

    const [currentSong, setCurrentSong] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    // const [isLoadingEpisode, setIsLoadingEpisode] = useState(false);
    // const [audioUrl, setAudioUrl] = useState("");
    // const [episodePlaying, setEpisodePlaying] = useState(null);

    const [songs, setSongs] = useState([]);
	const [libraryStatus, setLibraryStatus] = useState(false);
	const [songInfo, setSongInfo] = useState({
		currentTime: 0,
		duration: 0,
	});

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const fetchedSongs = await data(id);
				const episode_audio = await fetchAudio(id);
                console.log(episode_audio)
				var episode_details = episode_audio.episode_details;
				var currentEpisode = {
					artist: episode_details.creator_id || episode_details.artist,
					title: episode_details.title,
					cover: episode_details.img || episode_details.cover || "kupeel2.png",
					id: episode_details.id,
					audio: episode_audio.audio_presigned_url,
					// generaate 2 random colors
					color: episode_details.color || [Math.floor(Math.random() * 255), Math.floor(Math.random() * 255), Math.floor(Math.random() * 255)],
				}

                await setSongs([currentEpisode]);
				setCurrentSong(currentEpisode);
                
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error appropriately
            }
        };
        fetchData();
    }, [id]);

	// Functions
	const updateTimeHandler = (e) => {
		const currentTime = e.target.currentTime;
		const duration = e.target.duration;
		setSongInfo({ ...songInfo, currentTime, duration });
	};

	

    if (!currentSong) {
        return (<div className="spinner-container">
        <div className="spinner"></div>
        <div className="loading-text">Loading...</div>
      </div>); // Or any other loading state
    }

	return (
		<AppContainer libraryStatus={libraryStatus} >
			{/* <Song currentSong={currentSong} 
				style={{marginTop: '0px'}}
			/> */}
			<Player
				isPlaying={isPlaying}
				setIsPlaying={setIsPlaying}
				currentSong={currentSong}
				setCurrentSong={setCurrentSong}
				audioRef={audioRef}
				songInfo={songInfo}
				setSongInfo={setSongInfo}
				songs={songs}
				setSongs={setSongs}
			/>
			{/* <Credit /> */}
			<audio
				onLoadedMetadata={updateTimeHandler}
				onTimeUpdate={updateTimeHandler}
				ref={audioRef}
				src={currentSong.audio}
			/>
		</AppContainer>
	);
};

const AppContainer = styled.div`
	background-color: #AAEEEE;
	border-radius: 1rem;

	transition: all 0.5s ease;
	margin-left: ${(p) => (p.libraryStatus ? "20rem" : "0")};
	@media screen and (max-width: 768px) {
		margin-left: 0;
	}
`;


export default EmbeddedEpisode;
