// Credit.js
import React from "react";
import './Credit.css'; // Import the CSS file
import Language from '../../components/Language';
import SupportButton from './SupportButton'; // Import the SupportButton component

const Credit = ({ profile, profileId }) => {
	return (
		<div className="extplayer-credit-container">

			<div className="credit-bottom-right">
				{profileId && (
					<a
						className="extplayer-credit-link extplayer-credit-baseSelectorTitle"
						href={window.location.origin + "/#/app/public/profile/" + profileId}
						target="_blank"
						rel="noopener noreferrer"
						style={{ "marginRight": "8px" }}
					>
						See all playlists
					</a>
				)}

				<br />
				{profile && (
					<SupportButton
						supportLinkTitle={profile.supportLinkTitle}
						supportLinkPayment={profile.supportLinkPayment}
					/>
				)}
			</div>
			<br />
			<div className="credit-bottom-right">
				<Language text={false} reload={true} />
				<a
					className="extplayer-credit-link extplayer-credit-baseSelectorTitle extplayer-credit-endText"
					href="https://kupeel.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					Powered by Kupeel.
				</a>
			</div>
		</div>
	);
};

export default Credit;
