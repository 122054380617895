// SupportButton.js
import React from 'react';
import './SupportButton.css'; // Create a CSS file for styling

const SupportButton = ({ supportLinkTitle, supportLinkPayment }) => {
    if (!supportLinkTitle || !supportLinkPayment) {
        return null;
    }

    return (
        <a href={supportLinkPayment} className="support-button">
            {supportLinkTitle}
        </a>
    );
};

export default SupportButton;
