import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchEpisode } from './Fetching';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataContext } from '../DataContext';
import { faStar, faBars, faThumbsUp, faThumbsDown, faShareAlt, faFlag, faGlobe, faTrash } from '@fortawesome/free-solid-svg-icons';
import './EpisodePage.css'; // Assuming you have a corresponding CSS file
import ButtonPlayEpisode from './ButtonPlayEpisode';
import { pushGTMEvent } from '../GoogleAnalytics';
import QRCodeGenerator from './QrCode';

function EpisodePage({ handlePlay, handlePause, episodePlaying, isPlaying, isGenerating, isLoadingEpisode, getToken }) {
    const { t } = useTranslation(); // Initialize useTranslation hook
    const { id } = useParams();
    const { isAuthenticated, user, logout } = useAuth0();
    const navigate = useNavigate();
    const { language } = useContext(DataContext); // Use DataContext to get user details

    const [episode, setEpisode] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [isLanguagesLoading, setIsLanguagesLoading] = useState(false);
    const baseUrl = window.location.origin;
    const [publicPath, setPublicPath] = useState(null);
    const sharePath = `${baseUrl}/#/app/content/episode/${id}`;

    pushGTMEvent('page_view', 'Page Interaction', location.pathname);

    useEffect(() => {
        if (episode) {
            setPublicPath(`${baseUrl}/#/app/public/content/${episode.series}/${id}`);
            console.log('Public path:', publicPath);
        }
    }, [episode]);

    useEffect(() => {
        const loadEpisode = async () => {
            try {
                var token = await getToken();
                const data = await fetchEpisode(id, token);
                if (!data.id) {
                    navigate('/#/app'); // Redirect if no episode data
                } else {
                    setEpisode(data);
                }
            } catch (error) {
                console.error('Error fetching episode:', error);
                navigate('/#/app'); // Redirect on error
            } finally {
                setIsLoading(false);
            }
        };

        loadEpisode();
    }, [id, getToken]);

    const toggleDescription = () => {
        setIsDescriptionExpanded(!isDescriptionExpanded);
    };

    const getDescription = () => {
        if (episode.desc.length > 300 && !isDescriptionExpanded) {
            return `${episode.desc.substring(0, 300)}...`;
        }
        return episode.desc;
    };

    const handleShare = async () => {
        pushGTMEvent('feedback', 'Share', id);

        if (navigator.share) {
            try {
                await navigator.share({
                    title: episode.title,
                    text: t('checkOutEpisode'),
                    url: window.location.href,
                });
                console.log('Episode shared successfully');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            try {
                await navigator.clipboard.writeText(window.location.href);
                alert(t('urlCopied'));
            } catch (error) {
                console.error('Failed to copy URL:', error);
                alert(t('urlCopyFailed'));
            }
        }
    };

    const rateEpisode = async (episodeId, rating) => {
        var token = await getToken();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/episode/stats/rate`, {
                method: 'POST',
                headers: {
                    'Accept-Language': language || 'en', // 'en-US
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ episode_id: episodeId, rating: rating })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            if (data.success) {
                if (rating === 'report') {
                    window.alert(t('reportSuccess'));
                } else {
                    window.alert(t('ratingSuccess', { rating }));
                }
            } else {
                window.alert(data.message);
            }
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    };

    const handleLike = () => {
        pushGTMEvent('feedback', 'Like', id);
        rateEpisode(id, 'positive');
        console.log('Like button clicked');
    };

    const handleDislike = () => {
        pushGTMEvent('feedback', 'Dislike', id);
        rateEpisode(id, 'negative');
        console.log('Dislike button clicked');
    };

    const handleReport = () => {
        pushGTMEvent('feedback', 'Report', id);
        rateEpisode(id, 'report');
        console.log('Report button clicked');
    };

    const handleAccessSerie = () => {
        pushGTMEvent('feedback', 'Access Series', id);
        console.log('Access Series button clicked');
        navigate(`/app/content/serie/${episode.series}`);
    };

    // const fetchLanguages = async () => {
    //     var token = await getToken();
    //     setIsLanguagesLoading(true);
    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/metadata/` + episode.metadata_object_id, {
    //             headers: {
    //                 'Accept-Language': language || 'en', // 'en-US
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${token}`
    //             }
    //         });

    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }

    //         const data = await response.json();
    //         setLanguages(data.languages);
    //         console.log('Languages fetched:', data.languages);
    //     } catch (error) {
    //         console.error('Error fetching languages:', error);
    //     } finally {
    //         setIsLanguagesLoading(false);
    //     }
    // };

    const handleDelete = async () => {
        const confirmDelete = window.confirm(t('confirmDeleteEpisode'));
        if (!confirmDelete) return;

        var token = await getToken();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/episode/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            navigate('/app');
        } catch (error) {
            console.error('Error deleting episode:', error);
            alert(t('deleteFailed'));
        }
    };

    if (isLoading) {
        return (
            <div className="spinner-container">
                <div className="spinner"></div>
                <div className="loading-text">{t('loading')}</div>
            </div>
        );
    }

    return (
        <div className="episode-page">
            <div className="episode-header">
                <img src={episode.imageUrl} alt={episode.title} className="episode-image" />
                <div className="episode-details">
                    <h1>{episode.title}</h1>
                    <p>{getDescription()} {(episode.desc && episode.desc.length > 300) && (
                        <button onClick={toggleDescription} className="read-more-button">
                            {isDescriptionExpanded ? t('readLess') : t('readMore')}
                        </button>
                    )}</p>
                    <p>{t('voice')}: {episode.voice.name}</p>
                    <p>{t('style')}: {episode.style}</p>
                    <p>
                        {episode.voice.premium ?
                            <span style={{ backgroundColor: 'orange', color: 'white', padding: '5px', borderRadius: '5px' }}>
                                <FontAwesomeIcon icon={faStar} style={{ marginRight: '5px' }} />{t('premium')}
                            </span>
                            :
                            <span style={{ backgroundColor: 'gray', color: 'white', padding: '5px', borderRadius: '5px' }}>
                                {t('basic')}
                            </span>
                        }
                    </p>
                    <p>{t('published')}: {new Date(episode.created_at).toLocaleDateString()}</p>
                </div>
            </div>

            <div className="episode-player">
                <ButtonPlayEpisode
                    id={id}
                    isPlaying={isPlaying}
                    isGenerating={isGenerating}
                    isLoadingEpisode={isLoadingEpisode}
                    episodePlaying={episodePlaying}
                    handlePlay={handlePlay}
                    handlePause={handlePause}
                />
                <button onClick={handleAccessSerie} className="share-button">
                    <FontAwesomeIcon icon={faBars} /> {t('accessSeries')}
                </button>
                {episode.creator_id === user.sub && publicPath !== null && <QRCodeGenerator
                    btnTitle={t("getPublicAccess")}
                    linkProvided={publicPath}
                />}
            </div>
            <div className="episode-player">
                <button onClick={handleLike} className="like-button">
                    <FontAwesomeIcon icon={faThumbsUp} /> {t('like')}
                </button>
                <button onClick={handleDislike} className="dislike-button">
                    <FontAwesomeIcon icon={faThumbsDown} /> {t('dislike')}
                </button>
                <button onClick={handleReport} className="report-button">
                    <FontAwesomeIcon icon={faFlag} /> {t('report')}
                </button>
                <button onClick={handleShare} className="share-button">
                    <FontAwesomeIcon icon={faShareAlt} /> {t('share')}
                </button>
                {episode.creator_id === user.sub && (
                    <button onClick={handleDelete} className="delete-button">
                        <FontAwesomeIcon icon={faTrash} /> {t('delete')}
                    </button>
                )}
            </div>
        </div>
    );
}

export default EpisodePage;
