import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Thumbnail({ element, onPlay }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const open = (type, id) => {
    navigate(`/app/content/${type}/${id}`);
  };

  const playBtn = (e) => {
    e.stopPropagation();
    if (element.type === 'serie') {
      open(element.type, element.id);
    } else {
      onPlay(element.id, element.title);
    }
  };

  return (
    <div className="post" data-channel={element.channel} data-ts={element.ts} data-audio={element.audio}>
      <div className="episode">
        <div className="episode-cover" style={{ cursor: 'pointer' }} onClick={() => open(element.type, element.id)}>
          <img className="postImg" src={element.imageUrl} alt={element.altText} />
          <button className="button_player play-button" onClick={playBtn}>
            <FontAwesomeIcon icon={faPlay} />
          </button>
        </div>
        <div className="episode-info">
          <p className="episode-title">{element.title}</p>
        </div>
      </div>
    </div>
  );
}

export default Thumbnail;
