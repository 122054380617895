import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Example mapping of redirect IDs to URLs
// http://localhost:3000/#/redirect/669548ae8dd179e57f22bd68
const redirectMapping = {
  '669548ae8dd179e57f22bd68': '/app/public/content/668b9553211fe9fefd8bf898',
  // Add more mappings as needed
};

const Redirect = () => {
    const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(id)
    const redirectUrl = redirectMapping[id];
    if (redirectUrl) {
      // Use window.location.href for external redirects
      navigate(redirectUrl)
    } else {
      // Handle case where redirectId is not found
      console.error('Redirect ID not found');
      // Optionally, you could redirect to a 404 page or show an error message
    //   navigate('/404'); // Assuming you have a 404 route
    }
  }, [id, navigate]);

  // Render nothing as this component only handles redirection
  return null;
};

export default Redirect;
