import React, { useState, useEffect } from 'react'; // Import the useState and useEffect hooks
import './LibraryEpisode.css'; // Import the CSS file

const LibraryEpisode = ({ episode, currentEpisodeId, setCurrentEpisode, audioRef, isPlaying, episodes, setEpisodes }) => {

    const [isActive, setisActive] = useState(episode.active);

    const episodeSelectHandler = async () => {
        await setCurrentEpisode(episode);
        const curEpisode = episode;
        const episodeList = episodes;

        const newEpisodes = episodeList.map((episode) => {
            if (episode.id === curEpisode.id) {
                return {
                    ...episode,
                    active: true,
                };
            } else {
                return {
                    ...episode,
                    active: false,
                };
            }
        });
        setEpisodes(newEpisodes);

        if (isPlaying) {
            audioRef.current.play();
        }
    };
    

    return (
        <div
            className={`extplayer-libraryepisode-container ${episode.id === currentEpisodeId ? "active" : ""}`}
            onClick={episodeSelectHandler}
        >
            <img src={episode.img} alt={episode.title} className="extplayer-libraryepisode-img" />
            <div className="extplayer-libraryepisode-description">
                <h3 className="extplayer-library-h1">{episode.title}</h3>
                <h4 className="extplayer-library-h2">{episode.author}</h4>
            </div>
        </div>
    );
};

export default LibraryEpisode;
