import React, { useState, useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { pushGTMEvent } from '../GoogleAnalytics';
import { DataContext } from '../DataContext'; // Import DataContext
import { getDefaultLanguage } from './Fetching';
import './Parameters.css';
import Loading from './Loading';
import QRCodeGenerator from './QrCode';
import Language from './Language';

function Modal({ onClose, children }) {
  return (
    <div className="modal" style={{ width: "100%", height: "80vh" }}>
      <div className="modal-content" style={{ width: "100%", height: "80vh" }}>
        <span style={{ width: "60px", height: "60px", fontWeight: 900, fontSize: "20px", cursor: "pointer" }} className="close" onClick={onClose}>&times;</span>
        {children}
      </div>
    </div>
  );
}

function Parameters({ handlePause, getToken }) {
  const { t, i18n } = useTranslation();
  const { isAuthenticated, user, logout } = useAuth0();
  const { userSubscription, isLoadingUserSubscription, language, setLanguage } = useContext(DataContext); // Use DataContext to get user details and setLanguage
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmUnsubscribe, setConfirmUnsubscribe] = useState(false);
  const [profile, setProfile] = useState({});
  const [profileId, setProfileId] = useState(undefined);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileData, setSelectedFileData] = useState(null); // State for the base64 string
  const [isLogoutLoading, setIsLogoutLoading] = useState(false); // State for logout loading
  const [isUnsubscribeLoading, setIsUnsubscribeLoading] = useState(false); // State for unsubscribe loading
  const [errors, setErrors] = useState({});
  const isLoading = isLoadingUserSubscription;
  const baseUrl = window.location.origin;

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      setIsProfileLoading(true);
      try {
        const token = await getToken();
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/profile`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "Accept-Language": getDefaultLanguage() || "en"
          }
        });

        const profileData = await response.json();
        if (!profileData.profile) {
          profileData.profile = {};
        }
        setProfileId(profileData._id);
        setProfile(profileData.profile);
      } catch (err) {
        console.error('Error fetching profile:', err);
      } finally {
        setIsProfileLoading(false);
      }
    };

    fetchProfile();
  }, [getToken]);

  pushGTMEvent('page_view', t('pageInteraction'), '/app/parameters');

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedFileData(reader.result); // Set the base64 string
    };
    reader.readAsDataURL(file);
  };

  const handleSignOut = async () => {
    pushGTMEvent('parameters', t('signOut'), t('signOut'));
    handlePause();
    setIsLogoutLoading(true); // Start logout loading
    try {
      await logout({ logoutParams: { returnTo: window.location.origin } });
    } catch (err) {
      console.log(err);
      window.location.reload();
    } finally {
      setIsLogoutLoading(false); // Stop logout loading
    }
  };

  const handleUnsubscribe = async () => {
    pushGTMEvent('parameters', t('unsubscribe'), t('unsubscribe'));
    setIsUnsubscribeLoading(true); // Start unsubscribe loading
    try {
      const token = await getToken();
      await fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/subscription/delete`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: user.sub })
      });

      window.alert(t('youHaveBeenUnsubscribed'));
      await logout({ logoutParams: { returnTo: window.location.origin } });

    } catch (err) {
      window.alert(t('youHaveBeenUnsubscribed'));
      console.error('Error unsubscribing:', err);
    } finally {
      setIsUnsubscribeLoading(false); // Stop unsubscribe loading
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleProfileModal = () => {
    setIsProfileModalOpen(!isProfileModalOpen);
    setUpdatedProfile(profile);
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProfile(prevState => ({
      ...prevState,
      [name]: value
    }));

    // Validate inputs
    if (name === 'supportLinkTitle' && value.length > 15) {
      setErrors(prevState => ({
        ...prevState,
        supportLinkTitle: t('titleTooLong')
      }));
    } else {
      setErrors(prevState => ({
        ...prevState,
        supportLinkTitle: null
      }));
    }

    if (name === 'supportLinkPayment' && !isValidUrl(value)) {
      setErrors(prevState => ({
        ...prevState,
        supportLinkPayment: t('invalidUrl')
      }));
    } else {
      setErrors(prevState => ({
        ...prevState,
        supportLinkPayment: null
      }));
    }
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleProfileUpdate = async () => {
    if (errors.supportLinkTitle || errors.supportLinkPayment) {
      return;
    }

    try {
      const token = await getToken();
      var profiling = {profile: {}};
      profiling["profile"] = updatedProfile;

      if(profiling["profile"]["img"] === profile.img){
        delete profiling["profile"]["img"];
      }

      if (selectedFileData && selectedFileData !== profile.img) {
        profiling["profile"]["img"] = selectedFileData;
      }

      if("translations" in profiling["profile"]){
        delete profiling["profile"]["translations"];
      }

      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/profile`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          "Accept-Language": getDefaultLanguage() || "en"
        },
        body: JSON.stringify(profiling)
      });

      if(response.status === 200){
        const result = await response.json();
        window.alert(t('profileUpdated'));
        setProfile(result.profile);
      }
      toggleProfileModal();
    } catch (err) {
      console.error('Error updating profile:', err);
    }
  };

  if (isModalOpen) {
    return (
      <Modal onClose={toggleModal}>
        <iframe
          src="https://form.jotform.com/231156861426355"
          title="Iframe Example"
          width="100%"
          height="100%"
        />
      </Modal>
    );
  }

  if (confirmUnsubscribe) {
    return (
      <div className="card">
        <div className="details">
          <h1 id="baseSelectorTitle"> {t('leavingUs')} </h1>
          <div style={{ width: "70%", height: "0", paddingBottom: "70%", position: "relative" }}>
            <img src="/gif/leaving.gif" width="100%" height="100%" style={{ position: "absolute" }} frameBorder="0" className="giphy-embed" allowFullScreen></img>
          </div>
          <h1> {t('sureToUnsubscribe')} </h1>
          <div className="outLinks">
            <button onClick={handleUnsubscribe} className="unsubscribeButton">{t('yes')}</button>
            <button onClick={() => setConfirmUnsubscribe(false)} className="signOutButton">
              {t('no')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading || isProfileLoading || isLogoutLoading || isUnsubscribeLoading) {
    return <Loading />; // Display loading indicator while any of these operations are in progress
  }

  return (
    <div className="parameters-page">

      <div className="card">
        <div className="details">

          <h1 id="baseSelectorTitle">{t('subscriptionDetails')}</h1>
          <h2>{t('hereForYou')}</h2>

          {profileId && (
            <QRCodeGenerator
              btnTitle={t("getPublicAccess")}
              linkProvided={`${baseUrl}/#/app/public/profile/${profileId}`}
            />)}

          <p><strong>{t('yourEmail')}</strong> {user.email}</p>
          <p><strong>{t('currentLanguage')}</strong> {i18n.language}</p>
          <p><strong>{t('contactUs')}</strong> contact@kupeel.com</p>
          <p>{t('paymentMethodChange')}</p>
          <span style={{ height: "40px" }}></span>

          <Language/>


        </div>
      </div>

      <div className="card">
        <div className="details">
          <h1 id="baseSelectorTitle">{t('profileDetails')}</h1>
          {isProfileLoading ? (
            <p>{t('loadingProfile')}</p> // Or use a spinner component if you have one
          ) : (
              <>
                <p><strong>{t('img')}:</strong></p>
                {profile.img && <img src={profile.img} alt="Profile" className="profile-image" />}
                <p><strong>{t('name')}:</strong><br /> {profile.name}</p>
                <p><strong>{t('description')}:</strong><br /> {profile.desc}</p>
                <p><strong>{t('supportLinkTitle')}:</strong><br /> {profile.supportLinkTitle}</p>
                <p><strong>{t('supportLinkPayment')}:</strong><br /> {profile.supportLinkPayment}</p>
                <button onClick={toggleProfileModal} className="editProfileButton">{t('editProfile')}</button>

                {isProfileModalOpen && (
                  <Modal className="profile-edit-form-modal" onClose={toggleProfileModal}>
                    <div className="profile-edit-form">
                      <h2>{t('editProfile')}</h2>
                      <label>
                        {t('name')}: <input type="text" name="name" value={updatedProfile.name || ''} onChange={handleProfileChange} />
                      </label>
                      <label>
                        {t('description')}: <textarea name="desc" value={updatedProfile.desc || ''} onChange={handleProfileChange} />
                      </label>
                      <label>
                        {t('supportLinkTitle')}: <input type="text" name="supportLinkTitle" value={updatedProfile.supportLinkTitle || ''} onChange={handleProfileChange} />
                        {errors.supportLinkTitle && <span className="error">{errors.supportLinkTitle}</span>}
                      </label>
                      <label>
                        {t('supportLinkPayment')}: <input type="text" name="supportLinkPayment" value={updatedProfile.supportLinkPayment || ''} onChange={handleProfileChange} />
                        {errors.supportLinkPayment && <span className="error">{errors.supportLinkPayment}</span>}
                      </label>
                      <label>
                        {t('img')}: <input type="file" onChange={handleFileInput} />
                      </label>
                      <button onClick={handleProfileUpdate} className="saveButton">{t('save')}</button>
                    </div>
                  </Modal>
                )}
              </>
            )}
        </div>
      </div>

      <div className="card">
        <div className="details">

          <h1 id="baseSelectorTitle">{t('subscriptionDetails')}</h1>

          <div className="subscription-details">
            <p><strong>{t('subscriptionPlanName')}:</strong> {userSubscription.plan_name}</p>
            {userSubscription.plan_name !== "free" && (
              <>
                <p><strong>{t('subscriptionStatus')}:</strong> {userSubscription.subscribed ? t("inProgress") : t("terminated")}</p>
                <p><strong>{t('subscriptionAmount')}:</strong> {userSubscription.subscription_amount} {userSubscription.currency}</p>
                <p><strong>{t('nextPaymentDate')}:</strong> {new Date(userSubscription.subscription_renewal_date).toLocaleDateString()}</p>
                <p><strong>{t('cardLast4')}:</strong> **** **** **** {userSubscription.card_last4}</p>
                <p><strong>{t('cardExpiry')}:</strong> {userSubscription.card_exp_month}/{userSubscription.card_exp_year}</p>

              </>)}
            <button onClick={() => setConfirmUnsubscribe(true)} style={{ backgroundColor: "red" }} className="unsubscribeButton">{t('unsubscribeButton')}</button>

          </div>

        </div>
      </div>

      <div className="card">
        <div className="details">

          <h3>{t('areYouLeaving')}</h3>
          <div className="outLinks">
            <button onClick={handleSignOut} className="signOutButton">
              {t('signOut')}
            </button>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Parameters;
