export const languageOptions = [
    { value: "en", label: "🇬🇧  English" },
    // { value: "ja", label: "🇯🇵 Japanese" },
    // { value: "zh", label: "🇨🇳 Chinese" },
    // { value: "de", label: "🇩🇪 German" },
    // { value: "hi", label: "🇮🇳 Hindi" },
    { value: "fr", label: "🇫🇷 French" },
    {value:"ru",label:"🇷🇺 Russian"}
    // { value: "ko", label: "🇰🇷 Korean" },
    // { value: "pt", label: "🇧🇷🇵🇹 Portuguese" },
    // { value: "it", label: "🇮🇹 Italian" },
    // { value: "es", label: "🇪🇸🇲🇽 Spanish" },
    // { value: "id", label: "🇮🇩 Indonesian" },
    // { value: "nl", label: "🇳🇱 Dutch" },
    // { value: "tr", label: "🇹🇷 Turkish" },
    // { value: "fil", label: "🇵🇭 Filipino" },
    // { value: "pl", label: "🇵🇱 Polish" },
    // { value: "sv", label: "🇸🇪 Swedish" },
    // { value: "bg", label: "🇧🇬 Bulgarian" },
    // { value: "ro", label: "🇷🇴 Romanian" },
    // { value: "ar", label: "🇸🇦🇦🇪 Arabic" },
    // { value: "cs", label: "🇨🇿 Czech" },
    // { value: "el", label: "🇬🇷 Greek" },
    // { value: "fi", label: "🇫🇮 Finnish" },
    // { value: "hr", label: "🇭🇷 Croatian" },
    // { value: "ms", label: "🇲🇾 Malay" },
    // { value: "sk", label: "🇸🇰 Slovak" },
    // { value: "da", label: "🇩🇰 Danish" },
    // { value: "ta", label: "🇮🇳 Tamil" },
    // { value: "uk", label: "🇺🇦 Ukrainian" }
  ];